import React from 'react';
import { concatStyles } from 'utils';
import { Arrow } from './Arrow';
import { Step } from './Step';
export var StepProgress = function (_a) {
    var steps = _a.steps, className = _a.className, currentIndex = _a.currentIndex;
    return (React.createElement("ul", { className: concatStyles(['flex', className]) }, steps.map(function (step, i) { return (React.createElement(React.Fragment, { key: step.id },
        i > 0 ? React.createElement(Arrow, { disabled: currentIndex < i }) : null,
        React.createElement(Step, { step: step.id, label: step.label, active: currentIndex === i, disabled: currentIndex < i }))); })));
};
