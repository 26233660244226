import React from 'react';
import { remCalc, makeInitials, randomColor } from 'utils';
var CONTAINER_STYLES = 'bg-cover p-0 mb-auto flex flex-shrink-0 rounded-full';
var BORDER_STYLES = 'border-2 border-body';
export var Simple = function (_a) {
    var name = _a.name, color = _a.color, imageUrl = _a.imageUrl, _b = _a.border, border = _b === void 0 ? false : _b, _c = _a.maxInitials, maxInitials = _c === void 0 ? 1 : _c, _d = _a.size, size = _d === void 0 ? 48 : _d, _e = _a.className, className = _e === void 0 ? '' : _e;
    var circleStyles = {
        backgroundColor: color ? color : randomColor(),
        backgroundImage: imageUrl ? "url(" + imageUrl + ")" : 'none',
        height: size,
        width: size,
        flexBasis: remCalc(size),
    };
    var initialsStyles = {
        fontSize: remCalc(size / 2),
    };
    return (React.createElement("div", { className: [
            CONTAINER_STYLES,
            border ? BORDER_STYLES : null,
            className,
        ].join(' '), style: circleStyles }, name && !imageUrl ? (React.createElement("div", { className: "m-auto uppercase flex font-heading text-white", style: initialsStyles }, makeInitials(name, maxInitials))) : null));
};
