import React from 'react';
import { concatStyles } from 'utils';
var TABLE_STYLES = 'flex flex-col border-none';
export var Table = function (_a) {
    var children = _a.children;
    return (React.createElement("table", { className: TABLE_STYLES }, children));
};
var SHARED_STYLES = 'flex flex-col flex-grow flex-shrink border-0 bg-transparent my-auto mx-0 p-2 font-body text-left';
var CELL_STYLES = SHARED_STYLES + " text-body text-xs first:text-sm";
export var Cell = function (_a) {
    var width = _a.width, className = _a.className, colSpan = _a.colSpan, children = _a.children;
    return (React.createElement("td", { colSpan: colSpan, className: concatStyles(CELL_STYLES, className), style: { width: width !== null && width !== void 0 ? width : 'auto', flexBasis: width !== null && width !== void 0 ? width : 'auto' } }, children));
};
var HEADER_STYLES = SHARED_STYLES + " text-primary text-sm";
export var Header = function (_a) {
    var width = _a.width, className = _a.className, children = _a.children;
    return (React.createElement("th", { className: concatStyles(HEADER_STYLES, className), style: { width: width !== null && width !== void 0 ? width : 'auto', flexBasis: width !== null && width !== void 0 ? width : 'auto' } }, children));
};
var ROW_STYLES = 'flex flex-row border-0 border-t border-solid border-contentAreaBackground min-h-13 first:min-h-8 first:border-t-0 first:border-b first:border-solid first:border-contentAreaBackground even:bg-contentAreaBackground';
export var Row = function (_a) {
    var children = _a.children, className = _a.className;
    return React.createElement("tr", { className: concatStyles(className, ROW_STYLES) }, children);
};
