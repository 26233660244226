var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import * as Avatar from '../Avatar';
import { Title } from './Title';
import { Body } from './Body';
import { View } from './View';
import { DateTime } from 'luxon';
var BODY_STYLES = 'text-secondary';
var DATE_FORMAT = 'M/d/yyyy';
var formatDate = function (date) {
    var d = DateTime.fromISO(date);
    return d.isValid ? d.toFormat(DATE_FORMAT) : 'n/a';
};
export var Guardian = function (_a) {
    var name = _a.name, dateJoined = _a.dateJoined, dateLastAccessed = _a.dateLastAccessed, color = _a.color, avatarUrl = _a.avatarUrl, size = _a.size, view = __rest(_a, ["name", "dateJoined", "dateLastAccessed", "color", "avatarUrl", "size"]);
    return (React.createElement(View, __assign({}, view),
        React.createElement("div", { className: "flex" },
            React.createElement("div", { className: "mr-2" },
                React.createElement(Avatar.Simple, { name: name, maxInitials: 1, color: color, imageUrl: avatarUrl, size: size })),
            React.createElement("div", { className: "flex flex-col my-auto" },
                React.createElement(Title, { className: "text-primary font-black" }, name),
                React.createElement(Body, { className: BODY_STYLES },
                    "Joined ",
                    formatDate(dateJoined)),
                React.createElement(Body, { className: BODY_STYLES },
                    "Last login ",
                    formatDate(dateLastAccessed))))));
};
Guardian.displayName = 'Card.Guardian';
