/**
 * A default style to use when presenting react-hot-toast.
 */
export var toastTheme = {
    style: {
        border: '3px solid #FFC96B',
        background: '#FFC96B',
        padding: '16px',
        color: '#002442',
    },
    iconTheme: {
        primary: '#002442',
        secondary: '#FFFAEE',
    },
};
/**
 * The available responsive prefixes we can use in tailwind.
 */
var SIZES = ['', 'sm:', 'md:', 'lg:', 'xl:'];
/**
 * Generates a tailwind style or set of styles. This miimicks
 * the behavior of rebass but generates tailwind styles to yield
 * the same effect.
 * @param val The value or values for a responsive style.
 * @param prefix The tailwind prefix such as 'p-' or 'w-'
 */
export var makeResponsive = function (val, prefix) {
    var _a;
    return typeof val === 'object'
        ? (_a = val === null || val === void 0 ? void 0 : val.map(function (v, i) { return (v ? [SIZES[i], prefix, v].join('') : ''); }).filter(function (s) { return s.length > 0; }).join(' ')) !== null && _a !== void 0 ? _a : '' : val
        ? [prefix, val].join('')
        : '';
};
export var theme = {
    borderRadius: '0.25rem',
    breakpoints: ['48em', '64em', '75em'],
    colorAccent: '#FFC96B',
    colorActionable: '#44BB98',
    colorAlertBackground: '#ffc96b',
    colorBody: '#404040',
    colorBodyInverted: '#fff',
    colorClear: 'rgba(0,0,0,0)',
    colorContentAreaBackground: '#fff',
    colorContentAreaBackgroundAlt: '#f5f6f7',
    colorContentAreaBorder: '#dcddde',
    colorContentAreaBorderSelected: '#44BB98',
    colorDestructive: '#CE342A',
    colorError: '#CE342A',
    colorFavorite: '#f90',
    colorHeading: '#002442',
    colorHeadingSub: '#0778B1',
    colorInactive: '#9b9b9b',
    colorMetricBackground: '#6e54a0',
    colorNavBackground: '#153454',
    colorNavBackgroundActive: '#225072',
    colorNavBackgroundActiveAlt: '#307199',
    colorNavBackgroundAlt: '#225072',
    colorNavContent: '#9ebcdb',
    colorNavContentAlt: '#bed5ea',
    colorNavDivider: '#1f4368',
    colorNavDividerAlt: '#307199',
    colorNavSectionBackground: '#122a3a',
    colorNavSectionBackgroundAlt: '#1d435b',
    colorPrimary: '#0778B1',
    colorSecondary: '#002442',
    fontFamilyBody: '"Futura", "Futura Std","Helvetica Neue",Helvetica,Arial,sans-serif',
    fontFamilyHeader: '"Futura", "Futura Std", "Helvetica Neue",Helvetica,Arial,sans-serif',
    fontWeights: {
        body: 400,
        bold: 700,
        heading: 700,
    },
    fonts: {
        body: 'system-ui, sans-serif',
        heading: 'inherit',
        monospace: 'Menlo, monospace',
    },
};
