import React from 'react';
import { concatStyles } from 'utils';
var DROPDOWN_ITEM_STYLES = 'flex text-left flex-grow w-full p-2 hover:bg-gray-100 focus:outline-none focus:border-actionable focus:bg-actionable';
var getSelectedStyles = function (selected) {
    return selected
        ? 'text-white bg-actionable hover:bg-opacity-75 hover:bg-actionable'
        : 'text-body';
};
export var DropdownItem = function (_a) {
    var children = _a.children, onClick = _a.onClick, style = _a.style, className = _a.className, selected = _a.selected;
    var handleClick = function (e) {
        e.preventDefault();
        if (onClick) {
            onClick();
        }
    };
    return (React.createElement("button", { onClick: handleClick, style: style, className: concatStyles([
            DROPDOWN_ITEM_STYLES,
            getSelectedStyles(selected),
            className,
        ]) }, children));
};
DropdownItem.displayName = 'Form.DropdownItem';
