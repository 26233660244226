import React from 'react';
import { concatStyles } from 'utils';
var LIST_STYLES = 'flex flex-shrink-0 flex-col py-0 px-2 text-body font-body font-hairline text-sm leading-5 divide-gray-400';
var DIVIDE_STYLES = 'divide-y';
var DOUBLE_STYLES = 'divide-y-4 divide-double';
var NESTED_STYLED = '-mx-2';
export var List = function (_a) {
    var children = _a.children, divide = _a.divide, double = _a.double, className = _a.className, nested = _a.nested, _b = _a.marginClassName, marginClassName = _b === void 0 ? 'mx-0 my-4' : _b, style = _a.style;
    return (React.createElement("ul", { className: concatStyles([
            LIST_STYLES,
            divide ? DIVIDE_STYLES : '',
            double ? DOUBLE_STYLES : '',
            nested ? NESTED_STYLED : '',
            marginClassName,
            className,
        ]), style: style }, children));
};
List.displayName = 'Content.List';
