import React from 'react';
import { concatStyles } from 'utils';
var BUTTON_GROUP_STYLES = 'flex';
var BUTTON_GROUP_ITEM_STYLES = BUTTON_GROUP_STYLES;
var makeFlexDirection = function (direction) {
    return direction === 'column' ? 'flex-col' : 'flex-row';
};
var makeAdjacentStyles = function (direction) {
    return direction === 'column' ? 'mt-2' : 'ml-2';
};
var makeFlexGrow = function (grow) { return (grow ? 'flex-grow' : ''); };
export var ButtonGroup = function (_a) {
    var children = _a.children, _b = _a.direction, direction = _b === void 0 ? 'row' : _b, className = _a.className, itemClassName = _a.itemClassName, style = _a.style, itemStyle = _a.itemStyle, grow = _a.grow;
    return (React.createElement("ul", { className: concatStyles([
            BUTTON_GROUP_STYLES,
            makeFlexDirection(direction),
            makeFlexGrow(grow),
            className,
        ]), style: style }, React.Children.map(children, function (child, index) { return (React.createElement("li", { className: concatStyles([
            BUTTON_GROUP_ITEM_STYLES,
            itemClassName,
            makeFlexGrow(grow),
            index > 0 ? makeAdjacentStyles(direction) : '',
        ]), style: itemStyle }, child)); })));
};
ButtonGroup.displayName = 'Form.ButtonGroup';
