import React from 'react';
import { concatStyles } from 'utils';
var BODY_STYLES = 'font-body text-body text-xxs font-hairline p-0 m-0';
export var Body = function (_a) {
    var children = _a.children, className = _a.className, style = _a.style, tight = _a.tight;
    return (React.createElement("p", { className: concatStyles([
            BODY_STYLES,
            tight ? 'tracking-tight' : null,
            className,
        ]), style: style }, children));
};
Body.displayName = 'Card.Body';
