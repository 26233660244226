var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { DateTime } from 'luxon';
import React from 'react';
import * as Content from '../Content';
import * as Avatar from '../Avatar';
import { Title } from './Title';
import { Body } from './Body';
import { View } from './View';
import { truncate } from 'utils';
var colorForTag = function (status) {
    switch (status // this is actually EventInvitationStatus from the epc schema.
    ) {
        case 'CANCELLED':
            return 'destructive';
        case 'DECLINED':
            return 'warning';
        case 'PENDING':
            return 'warning';
        default:
            return undefined;
    }
};
export var InvitationAttendee = function (_a) {
    var invite = _a.invite, handleClick = _a.onClick, showStatus = _a.showStatus, view = __rest(_a, ["invite", "onClick", "showStatus"]);
    return (React.createElement(View, __assign({}, view),
        React.createElement("button", { className: "flex flex-grow text-left", onClick: handleClick },
            React.createElement("div", { className: "mr-2" },
                React.createElement(Avatar.Simple, { color: invite.profileColor || '', name: invite.profileName, maxInitials: 2, imageUrl: invite.profileIconUrl || '' })),
            React.createElement("div", { className: "flex flex-col my-auto" },
                React.createElement(Title, null, invite.profileName),
                React.createElement(Body, null,
                    invite.profileDescription,
                    React.createElement("br", null),
                    invite.profileDescription2),
                showStatus ? (React.createElement("p", { className: "flex mt-1 mr-auto" },
                    React.createElement(Content.Bubble, { bubbleStyle: colorForTag(invite.status) }, invite.status.replace('_', ' ')))) : null))));
};
InvitationAttendee.displayName = 'Card.InvitationAttendee';
export var InvitationProfile = function (_a) {
    var invite = _a.invite, handleClick = _a.onClick, showStatus = _a.showStatus, view = __rest(_a, ["invite", "onClick", "showStatus"]);
    return (React.createElement(View, __assign({}, view),
        React.createElement("button", { className: "flex flex-grow text-left", onClick: handleClick },
            React.createElement("div", { className: "mr-2" },
                React.createElement(Avatar.Simple, { color: invite.profileColor || '', name: invite.profileName, maxInitials: 2, imageUrl: invite.profileIconUrl || '' })),
            React.createElement("div", { className: "flex flex-col my-auto" },
                React.createElement(Title, null, invite.profileName),
                React.createElement(Body, null,
                    invite.status,
                    " \u2022 Invited",
                    ' ',
                    DateTime.fromISO(invite.createdAt).toRelative()),
                showStatus ? (React.createElement("p", { className: "flex mt-1 mr-auto" },
                    React.createElement(Content.Bubble, { bubbleStyle: colorForTag(invite.status) }, invite.status.replace('_', ' ')))) : null))));
};
InvitationProfile.displayName = 'Card.InvitationProfile';
export var InvitationEvent = function (_a) {
    var invite = _a.invite, handleClick = _a.onClick, truncateLimit = _a.truncate, showStatus = _a.showStatus, view = __rest(_a, ["invite", "onClick", "truncate", "showStatus"]);
    return (React.createElement(View, __assign({}, view),
        React.createElement("button", { className: "flex flex-grow text-left", onClick: handleClick },
            React.createElement("div", { className: "mr-2" },
                React.createElement(Avatar.Simple, { color: invite.eventColor || '', name: invite.eventName, maxInitials: 1, imageUrl: invite.eventIconUrl || '' })),
            React.createElement("div", { className: "flex flex-col my-auto" },
                React.createElement(Title, null, truncate(invite.eventName, truncateLimit !== null && truncateLimit !== void 0 ? truncateLimit : 155)),
                React.createElement(Body, null,
                    "Invited ",
                    DateTime.fromISO(invite.createdAt).toRelative()),
                showStatus ? (React.createElement("p", { className: "flex mt-1 mr-auto" },
                    React.createElement(Content.Bubble, { bubbleStyle: colorForTag(invite.status) }, invite.status.replace('_', ' ')))) : null))));
};
InvitationEvent.displayName = 'Card.InvitationEvent';
