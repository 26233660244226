/**
 * A utility method to truncate a string at maximum length.
 * @param str The initial string to truncate.
 * @param length The maximum allowed length.
 * @param ending A string to terminate the truncated value with (defaults to '...')
 */
export var truncate = function (str, length, ending) {
    return str.length > (length || 100)
        ? str.substring(0, (length || 100) - (ending || '...').length) +
            (ending || '...')
        : str;
};
