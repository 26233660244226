import React, { useContext } from 'react';
import { concatStyles, remCalc } from 'utils';
import { NavigatorContext } from './NavigatorContext';
var DRAWER_GENERIC_STYLES = 'flex flex-row relative list-none box-border m-0';
var CONTENT_STYLES = 'flex-1 p-4';
var DIVIDER_STYLES = 'block absolute border-b border-solid bg-navDivider';
/**
 * A generic container for alternate content embedded in
 * the primary navigation drawer.
 */
export var Generic = function (_a) {
    var children = _a.children, className = _a.className, style = _a.style;
    var theme = useContext(NavigatorContext).theme;
    var isSecondary = theme === 'secondary';
    return (React.createElement("li", { className: concatStyles(DRAWER_GENERIC_STYLES, className), style: style },
        React.createElement("div", { className: CONTENT_STYLES }, children),
        React.createElement("span", { style: {
                bottom: -1,
                left: isSecondary ? remCalc(18) : 0,
                right: isSecondary ? remCalc(18) : 0,
            }, className: concatStyles([
                DIVIDER_STYLES,
                isSecondary ? 'bg-navDividerAlt' : '',
            ]) })));
};
