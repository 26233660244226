import React, { useContext, useState } from 'react';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DrawerItem } from './DrawerItem';
import { Href } from './Item';
import { NavigatorContext, NavigatorItemContext, } from './NavigatorContext';
var SHARED_STYLES = 'flex box-border m-0 w-full';
var CONTAINER_STYLES = SHARED_STYLES + " flex-col";
var FLEX_CONTAINER_STYLES = SHARED_STYLES;
/**
 * An item for the primary navigation drawer representing a
 * collapsable section.
 * @param props Configuration for the item.
 */
export var Accordion = function (_a) {
    var active = _a.active, children = _a.children, handleToggle = _a.handleToggle, id = _a.id, label = _a.label;
    var navigator = useContext(NavigatorContext);
    var _b = useState(false), hovering = _b[0], setHovering = _b[1];
    var handleMouse = function (over) { return function () {
        if (hovering !== over) {
            setHovering(over);
        }
    }; };
    var handleClick = function (e) {
        e.preventDefault();
        if (handleToggle && id) {
            handleToggle(id);
        }
    };
    return (React.createElement(DrawerItem, { appearanceTheme: navigator.theme, active: active, onMouseOver: handleMouse(true), onMouseOut: handleMouse(false) },
        React.createElement(NavigatorItemContext.Provider, { value: { active: active !== null && active !== void 0 ? active : false, hovering: hovering, theme: navigator.theme } },
            React.createElement("div", { className: CONTAINER_STYLES },
                React.createElement("div", { className: FLEX_CONTAINER_STYLES, onClick: handleClick },
                    React.createElement(Href, { active: active, href: "#", appearanceTheme: navigator.theme, onClick: handleClick },
                        React.createElement("span", { className: FLEX_CONTAINER_STYLES }, label),
                        React.createElement(FontAwesomeIcon, { className: "my-auto mx-3", icon: active ? faChevronUp : faChevronDown }))),
                active ? children : null))));
};
