var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { css } from './styled-components';
var CSSUnit;
(function (CSSUnit) {
    CSSUnit["Px"] = "px";
    CSSUnit["Rem"] = "rem";
    CSSUnit["Em"] = "em";
})(CSSUnit || (CSSUnit = {}));
export var gutterWidth = { value: 1, unit: CSSUnit.Rem };
export var outerMargin = { value: 2, unit: CSSUnit.Rem };
export var gutterCompensation = "" + gutterWidth.value * 0.5 * -1 + gutterWidth.unit;
export var halfGutterWidth = "" + gutterWidth.value * 0.5 + gutterWidth.unit;
export var xsMin = 30;
export var smMin = 48;
export var mdMin = 64;
export var lgMin = 75;
export var screenXsMin = xsMin;
export var screenSmMin = smMin;
export var screenMdMin = mdMin;
export var screenLgMin = lgMin;
export var containerSm = "" + (smMin + gutterWidth.value) + gutterWidth.unit;
export var containerMd = "" + (mdMin + gutterWidth.value) + gutterWidth.unit;
export var containerLg = "" + (lgMin + gutterWidth.value) + gutterWidth.unit;
/* tslint:disable:object-literal-sort-keys */
var sizes = {
    minXs: '375px',
    minSm: '544px',
    minMd: '768px',
    minLg: '992px',
    minXl: '1200px',
};
/* tslint:enable:object-literal-sort-keys */
export var media = Object.keys(sizes).reduce(function (acc, label) {
    acc[label] = function (literals) {
        var placeholders = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            placeholders[_i - 1] = arguments[_i];
        }
        return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    @media (min-width: ", ") {\n      ", "\n    }\n  "], ["\n    @media (min-width: ", ") {\n      ", "\n    }\n  "])), sizes[label], css.apply(void 0, __spreadArrays([literals], placeholders)));
    };
    return acc;
}, {});
/* tslint:disable:object-literal-sort-keys */
var viewports = {
    small: '48em',
    medium: '64em',
    large: '75em',
};
/* tslint:enable:object-literal-sort-keys */
export var viewport = Object.keys(viewports).reduce(function (acc, label) {
    acc[label] = function (literals) {
        var placeholders = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            placeholders[_i - 1] = arguments[_i];
        }
        return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    @media only screen and (min-width: ", ") {\n      ", "\n    }\n  "], ["\n    @media only screen and (min-width: ", ") {\n      ", "\n    }\n  "])), viewports[label], css.apply(void 0, __spreadArrays([literals], placeholders)));
    };
    return acc;
}, {});
var templateObject_1, templateObject_2;
