var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { remCalc } from '../styled-components';
export var Label = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  color: ", ";\n  font-family: ", ";\n  padding: 0 0 4px 0;\n  margin: 0;\n  font-size: ", ";\n  flex-grow: 0;\n  flex-direction: row;\n"], ["\n  display: flex;\n  color: ",
    ";\n  font-family: ", ";\n  padding: 0 0 4px 0;\n  margin: 0;\n  font-size: ", ";\n  flex-grow: 0;\n  flex-direction: row;\n"])), function (p) {
    return p.secondary ? p.theme.colorSecondary : p.theme.colorPrimary;
}, function (p) { return p.theme.fontFamilyBody; }, remCalc(13));
var templateObject_1;
