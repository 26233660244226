var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ErrorMessage, useField } from 'formik';
import * as React from 'react';
import { Flex } from 'rebass';
import { DecorativeRadio, DecorativeRadioIndicator, Descriptive, Label, RequiredIcon, } from '../Form';
import styled, { remCalc } from '../styled-components';
import { Error } from './Error';
import { FieldContainer } from './FieldContainer';
export var FieldGroupAppearanceSize;
(function (FieldGroupAppearanceSize) {
    FieldGroupAppearanceSize["Default"] = "default";
    FieldGroupAppearanceSize["Small"] = "small";
})(FieldGroupAppearanceSize || (FieldGroupAppearanceSize = {}));
export var BasicCheckboxLabel = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  box-sizing: border-box;\n  cursor: ", ";\n  color: ", ";\n  flex-direction: row;\n  display: flex;\n  font-family: ", ";\n  font-size: ", ";\n  flex: 1 0 auto;\n  width: auto;\n  opacity: ", ";\n  padding: ", " ", " ", " ", ";\n  pointer-events: ", ";\n  text-align: left;\n  & > span {\n    flex: 1 1 auto;\n    margin: auto 10px;\n  }\n"], ["\n  box-sizing: border-box;\n  cursor: ", ";\n  color: ", ";\n  flex-direction: row;\n  display: flex;\n  font-family: ", ";\n  font-size: ", ";\n  flex: 1 0 auto;\n  width: auto;\n  opacity: ", ";\n  padding: ", " ", " ", " ", ";\n  pointer-events: ", ";\n  text-align: left;\n  & > span {\n    flex: 1 1 auto;\n    margin: auto 10px;\n  }\n"])), function (p) { return (p.disabled ? 'auto' : 'pointer'); }, function (p) { return p.theme.colorBody; }, function (p) { return p.theme.fontFamilyBody; }, remCalc(10), function (p) { return (p.disabled ? 0.5 : 1); }, remCalc(4), remCalc(4), remCalc(4), remCalc(0), function (p) { return (p.disabled ? 'none' : 'auto'); });
export var OptionLabel = styled(Flex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  box-sizing: border-box;\n  cursor: ", ";\n  color: ", ";\n  flex-direction: row;\n  font-family: ", ";\n  font-size: ", ";\n  flex: 1 0 ", ";\n  width: ", ";\n  opacity: ", ";\n  padding: ", " ", " ", " ", ";\n  pointer-events: ", ";\n\n  & > span {\n    flex: 1 1 auto;\n    margin: auto 10px;\n  }\n"], ["\n  box-sizing: border-box;\n  cursor: ", ";\n  color: ", ";\n  flex-direction: row;\n  font-family: ", ";\n  font-size: ",
    ";\n  flex: 1 0 ", ";\n  width: ", ";\n  opacity: ", ";\n  padding: ", " ", " ", " ", ";\n  pointer-events: ", ";\n\n  & > span {\n    flex: 1 1 auto;\n    margin: auto 10px;\n  }\n"])), function (p) { return (p.disabled ? 'auto' : 'pointer'); }, function (p) { return p.theme.colorBody; }, function (p) { return p.theme.fontFamilyBody; }, function (p) {
    return p.appearanceSize === FieldGroupAppearanceSize.Small
        ? remCalc(10)
        : remCalc(12);
}, function (p) { return p.basis || 'auto'; }, function (p) { return p.basis || 'auto'; }, function (p) { return (p.disabled ? 0.5 : 1); }, remCalc(4), remCalc(4), remCalc(4), remCalc(0), function (p) { return (p.disabled ? 'none' : 'auto'); });
var OptionsGroupContainer = styled(Flex)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex-direction: ", ";\n  flex-wrap: wrap;\n  margin-bottom: ", ";\n"], ["\n  flex-direction: ", ";\n  flex-wrap: wrap;\n  margin-bottom: ", ";\n"])), function (p) { return (p.inline ? 'row' : 'column'); }, remCalc(8));
function FieldGroupFieldRender() {
    var RendererComponent = function (_a) {
        var isChecked = _a.isChecked, label = _a.label, name = _a.name, onChange = _a.onChange, value = _a.value, p = __rest(_a, ["isChecked", "label", "name", "onChange", "value"]);
        var field = useField(name)[0];
        var handleChange = function () {
            if (onChange) {
                onChange({ name: name, value: value, fieldValue: field.value });
            }
        };
        var checked = isChecked
            ? isChecked({ name: name, value: value, fieldValue: field.value })
            : false;
        var disabled = typeof p.allOrNothingValue === 'string' &&
            typeof field.value === 'object' &&
            p.allOrNothingValue !== value &&
            field.value.includes(p.allOrNothingValue);
        var Container = p.container || DecorativeRadio;
        var Indicator = p.indicator || DecorativeRadioIndicator;
        return (React.createElement(OptionLabel, { as: "label", basis: p.width ? Math.round(p.width * 100) + "%" : null, disabled: disabled, appearanceSize: p.appearanceSize },
            React.createElement(Container, null,
                checked ? React.createElement(Indicator, null) : null,
                React.createElement("input", { checked: checked, type: p.inputType, onChange: handleChange })),
            React.createElement("span", null, label)));
    };
    return RendererComponent;
}
export function FieldGroupRenderer() {
    var FieldGroupField = FieldGroupFieldRender();
    var GeneratedRenderer = function (_a) {
        var allOrNothingValue = _a.allOrNothingValue, appearanceSize = _a.appearanceSize, container = _a.container, disabled = _a.disabled, isChecked = _a.isChecked, indicator = _a.indicator, inline = _a.inline, label = _a.label, description = _a.description, required = _a.required, name = _a.name, values = _a.values, inputType = _a.inputType, inputWidth = _a.inputWidth, onChange = _a.onChange;
        return (React.createElement(FieldContainer, { disabled: disabled },
            label && (React.createElement(Label, { htmlFor: name },
                label,
                required && React.createElement(RequiredIcon, null))),
            description && React.createElement(Descriptive, null, description),
            React.createElement(OptionsGroupContainer, { inline: inline }, values.map(function (radio) {
                return (React.createElement(FieldGroupField, { appearanceSize: appearanceSize, allOrNothingValue: allOrNothingValue, inputType: inputType || 'checkbox', container: container, label: radio.label, value: radio.value, name: name, key: name + "," + radio.value, indicator: indicator, onChange: onChange, isChecked: isChecked, width: inputWidth }));
            })),
            React.createElement(ErrorMessage, { name: name, component: Error })));
    };
    return GeneratedRenderer;
}
var templateObject_1, templateObject_2, templateObject_3;
