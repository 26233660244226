import React from 'react';
import { Body } from '../Content';
import { Label } from '../Form';
import { flat } from 'utils';
export var Attribute = function (_a) {
    var label = _a.label, children = _a.children, _b = _a.secondary, secondary = _b === void 0 ? false : _b, _c = _a.value, value = _c === void 0 ? [] : _c;
    return (React.createElement("p", { className: "py-2 w-full m-0 box-border" },
        React.createElement(Label, { secondary: secondary }, label),
        flat([value]).map(function (v, i) {
            return v ? (React.createElement(Body, { key: "" + label + i, marginClassNames: "m-0" }, v)) : null;
        }),
        children ? React.createElement(Body, { marginClassNames: "m-0" }, children) : null));
};
