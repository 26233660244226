import React from 'react';
import * as Card from '../Card';
export var Matches = function (_a) {
    var matches = _a.matches, handleSelectMatch = _a.onSelectMatch;
    var handleClick = function (match) {
        return function (e) {
            e.preventDefault();
            if (handleSelectMatch) {
                handleSelectMatch(match);
            }
        };
    };
    return (React.createElement("div", { className: "mb-4 flex flex-col sm:flex-row flex-grow" },
        React.createElement(Card.View, { internal: true, className: "p-2 my-2 flex-wrap" }, matches
            ? matches.map(function (match) { return (React.createElement("div", { key: match.id, className: "p-1 w-auto sm:w-1/2" },
                React.createElement(Card.Match, { match: match, className: "px-2 py-3", onClick: handleClick(match) }))); })
            : null)));
};
