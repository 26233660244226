var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { DecorativeCheckBox, DecorativeCheckBoxSmall, DecorativeCheckboxIndicator, } from '../Form';
import { Radio } from './Radio';
export var CheckBox = function (props) { return (React.createElement(Radio, __assign({}, props, { indicator: DecorativeCheckboxIndicator, container: DecorativeCheckBox, inputType: "checkbox" }))); };
export var CheckBoxSmall = function (props) { return (React.createElement(Radio, __assign({}, props, { indicator: DecorativeCheckboxIndicator, container: DecorativeCheckBoxSmall, inputType: "checkbox" }))); };
