import React, { useState } from 'react';
import { remCalc, concatStyles } from 'utils';
var BORDER_STYLES = "absolute border-b";
var ItemBorder = function (_a) {
    var appearanceTheme = _a.appearanceTheme, topItem = _a.topItem;
    var secondary = appearanceTheme === 'secondary';
    var paddingX = secondary ? remCalc(18) : 0;
    var borderColor = secondary ? 'border-navDividerAlt' : 'border-navDivider';
    return (React.createElement("span", { className: concatStyles([BORDER_STYLES, borderColor]), style: {
            top: topItem ? '-1px' : 'auto',
            bottom: topItem ? 'auto' : '-1px',
            left: paddingX,
            right: paddingX,
        } }));
};
var ITEM_STYLES = "flex m-0 px-0 py-0 relative";
export var DrawerItem = function (_a) {
    var children = _a.children, topItem = _a.topItem, active = _a.active, appearanceTheme = _a.appearanceTheme, onMouseOver = _a.onMouseOver, onMouseOut = _a.onMouseOut;
    var _b = useState(false), hover = _b[0], setHover = _b[1];
    var handleMouseOver = function (e) {
        onMouseOver === null || onMouseOver === void 0 ? void 0 : onMouseOver(e);
        setHover(true);
    };
    var handleMouseOut = function (e) {
        onMouseOut === null || onMouseOut === void 0 ? void 0 : onMouseOut(e);
        setHover(false);
    };
    return (React.createElement("li", { className: ITEM_STYLES, onMouseOut: handleMouseOut, onMouseOver: handleMouseOver },
        topItem && !active ? (React.createElement(ItemBorder, { appearanceTheme: appearanceTheme, topItem: true })) : null,
        children,
        !hover && !active ? (React.createElement(ItemBorder, { appearanceTheme: appearanceTheme })) : null));
};
