import React from 'react';
import { concatStyles } from 'utils';
var COLOR_OPTION_STYLES = 'rounded border-2 border-solid h-6 cursor-pointer block w-full hover:border-actionable';
var DEFAULT_BORDER_COLOR_STYLES = 'border-transparent';
var SELECTED_BORDER_STYLES = 'border-accent';
export var ColorOption = function (_a) {
    var color = _a.color, selected = _a.selected, onClick = _a.onClick;
    var handleOnClick = function () { return onClick === null || onClick === void 0 ? void 0 : onClick(color); };
    return (React.createElement("button", { onClick: handleOnClick, style: { backgroundColor: color }, className: concatStyles([
            COLOR_OPTION_STYLES,
            selected ? SELECTED_BORDER_STYLES : DEFAULT_BORDER_COLOR_STYLES,
        ]) }));
};
