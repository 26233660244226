import React from 'react';
import { concatStyles } from 'utils';
var PRIMARY_STYLES = 'flex flex-col w-full sm:h-full relative bg-white py-8 px-6';
var SECONDARY_STYLES = 'flex flex-col py-8 px-6';
var SMALL_STYLES = 'sm:w-1/2 xl:w-1/3';
var LARGE_STYLES = 'sm:w-1/2 xl:w-2/3';
export var Column = function (_a) {
    var children = _a.children, secondary = _a.secondary, className = _a.className, large = _a.large;
    return (React.createElement("div", { className: concatStyles([
            secondary ? SECONDARY_STYLES : PRIMARY_STYLES,
            large ? LARGE_STYLES : SMALL_STYLES,
            className,
        ]) }, children));
};
Column.displayName = 'Layout.Column';
