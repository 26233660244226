var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import { decamelize } from 'humps';
import * as React from 'react';
import styled, { remCalc } from '../styled-components';
import { flat } from 'utils';
var ErrorList = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  list-style: none;\n  padding: ", " ", ";\n  margin: 0;\n  display: flex;\n  flex: 0 0 auto;\n  flex-direction: column;\n"], ["\n  list-style: none;\n  padding: ", " ", ";\n  margin: 0;\n  display: flex;\n  flex: 0 0 auto;\n  flex-direction: column;\n"])), remCalc(8), remCalc(12));
var ErrorItem = styled.li(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: ", " 0;\n  display: flex;\n  flex-direction: row;\n  font-size: ", ";\n  font-family: ", ";\n"], ["\n  margin: ", " 0;\n  display: flex;\n  flex-direction: row;\n  font-size: ", ";\n  font-family: ", ";\n"])), remCalc(4), remCalc(12), function (p) { return p.theme.fontFamilyBody; });
var Icon = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  opacity: 0.75rem;\n  padding-right: 0.5rem;\n  display: block;\n  color: ", ";\n"], ["\n  opacity: 0.75rem;\n  padding-right: 0.5rem;\n  display: block;\n  color: ", ";\n"])), function (p) { return p.theme.colorError; });
export function ErrorSummaryRenderer() {
    return function (props) {
        var errors = props.errors || {};
        return React.createElement(ErrorSummary, { errors: errors });
    };
}
export var ErrorOutput = function (_a) {
    var field = _a.field, error = _a.error;
    return (React.createElement(ErrorItem, null,
        React.createElement(Icon, null,
            React.createElement(FontAwesomeIcon, { icon: faExclamationTriangle })),
        ' ',
        React.createElement("span", null,
            field.charAt(0).toUpperCase(),
            decamelize(field).split('_').join(' ').slice(1),
            ' ',
            (error || 'unknown').toLowerCase())));
};
var errorsAsOutput = function (errors) {
    return Object.keys(errors || {}).map(function (field) {
        var error = errors[field];
        if (typeof error === 'object') {
            if (error.length) {
                return errorsAsOutput(error[0]);
            }
            return errorsAsOutput(error);
        }
        return React.createElement(ErrorOutput, { key: "error" + field, field: field, error: error });
    });
};
export var ErrorSummary = function (_a) {
    var errors = _a.errors, minSubmits = _a.minSubmits;
    var submitCount = useFormikContext().submitCount;
    var errorOutput = flat(__spreadArrays(errorsAsOutput(errors)));
    return errors &&
        Object.keys(errors).length > 0 &&
        submitCount >= (minSubmits || 1) ? (React.createElement(ErrorList, null, errorOutput)) : null;
};
ErrorSummary.displayName = 'Form.ErrorSummary';
var templateObject_1, templateObject_2, templateObject_3;
