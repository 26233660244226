import React from 'react';
import { backgroundStyles } from '../Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Indicators from '../Indicators';
import { concatStyles } from 'utils';
var ROUND_BUTTON_STYLES = 'flex justify-center items-center hover:bg-opacity-75 border-0 rounded-full cursor-pointer text-contentAreaBackground w-8 h-8';
export var IconButton = function (_a) {
    var icon = _a.icon, onClick = _a.onClick, loading = _a.loading, _b = _a.appearance, appearance = _b === void 0 ? 'primary' : _b, style = _a.style, className = _a.className;
    var handleClick = function (e) {
        e.preventDefault();
        onClick && onClick();
    };
    return (React.createElement("button", { className: concatStyles([
            ROUND_BUTTON_STYLES,
            backgroundStyles(appearance),
            className,
        ]), onClick: handleClick, style: style }, loading ? (React.createElement(Indicators.Spinner, { className: "m-auto" })) : (React.createElement(FontAwesomeIcon, { icon: icon, size: "1x" }))));
};
