var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { Flex } from 'rebass';
import { DecorativeRadio, DecorativeRadioIndicator } from '../Form';
import styled, { remCalc } from '../styled-components';
var OptionLabel = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  box-sizing: border-box;\n  cursor: ", ";\n  color: ", ";\n  flex-direction: row;\n  font-family: ", ";\n  font-size: ", ";\n  flex: 1 0 ", ";\n  width: ", ";\n  opacity: ", ";\n  padding: ", " ", " ", " ", ";\n  pointer-events: ", ";\n"], ["\n  box-sizing: border-box;\n  cursor: ", ";\n  color: ", ";\n  flex-direction: row;\n  font-family: ", ";\n  font-size: ",
    ";\n  flex: 1 0 ", ";\n  width: ", ";\n  opacity: ", ";\n  padding: ", " ", " ", " ", ";\n  pointer-events: ", ";\n"])), function (p) { return (p.disabled ? 'auto' : 'pointer'); }, function (p) { return p.theme.colorBody; }, function (p) { return p.theme.fontFamilyBody; }, function (p) {
    return p.appearanceSize === 'small' ? remCalc(10) : remCalc(12);
}, function (p) { return p.basis || 'auto'; }, function (p) { return p.basis || 'auto'; }, function (p) { return (p.disabled ? 0.5 : 1); }, remCalc(4), remCalc(4), remCalc(4), remCalc(0), function (p) { return (p.disabled ? 'none' : 'auto'); });
var LabelText = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 1 1 auto;\n  margin: auto 4px auto 6px;\n"], ["\n  display: flex;\n  flex: 1 1 auto;\n  margin: auto 4px auto 6px;\n"])));
var OptionCount = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n  display: flex;\n  flex: 0 1 auto;\n  padding: 3px 3px;\n  margin: auto 0 auto auto;\n  border-radius: ", ";\n"], ["\n  background: ", ";\n  color: ", ";\n  display: flex;\n  flex: 0 1 auto;\n  padding: 3px 3px;\n  margin: auto 0 auto auto;\n  border-radius: ", ";\n"])), function (p) { return p.theme.colorAccent; }, function (p) { return p.theme.colorSecondary; }, function (p) { return p.theme.borderRadius; });
export var Radio = function (_a) {
    var checked = _a.checked, disabled = _a.disabled, label = _a.label, name = _a.name, count = _a.count, onChange = _a.onChange, value = _a.value, p = __rest(_a, ["checked", "disabled", "label", "name", "count", "onChange", "value"]);
    var handleChange = function () {
        if (onChange) {
            onChange(!checked);
        }
    };
    var Container = p.container || DecorativeRadio;
    var Indicator = p.indicator || DecorativeRadioIndicator;
    return (React.createElement(OptionLabel, { as: "label", basis: p.width ? Math.round(p.width * 100) + "%" : null, disabled: disabled || false, appearanceSize: p.appearanceSize },
        React.createElement(Container, null,
            checked ? React.createElement(Indicator, null) : null,
            React.createElement("input", { checked: checked, type: p.inputType || 'radio', onChange: handleChange })),
        label ? React.createElement(LabelText, null, label) : null,
        count ? React.createElement(OptionCount, null, count) : null));
};
var templateObject_1, templateObject_2, templateObject_3;
