import React from 'react';
import { concatStyles, remCalc } from 'utils';
var INDICATOR_STYLES = 'cursor-pointer opacity-100 relative bg-transparent border-2 border-solid border-body border-opacity-25 hover:border-actionable hover:border-opacity-25 rounded-full';
var DISABLED_STYLES = 'opacity-50';
export var Indicator = function (_a) {
    var color = _a.color, size = _a.size, disabled = _a.disabled, onClick = _a.onClick, children = _a.children;
    var handleOnClick = function () { return onClick === null || onClick === void 0 ? void 0 : onClick(); };
    var INDICATOR_INLINE_STYLES = {
        backgroundColor: color,
        height: remCalc(size),
        width: remCalc(size),
    };
    return (React.createElement("div", { onClick: handleOnClick, style: INDICATOR_INLINE_STYLES, className: concatStyles([
            INDICATOR_STYLES,
            disabled ? DISABLED_STYLES : '',
        ]) }, children));
};
