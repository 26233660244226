import React from 'react';
import { concatStyles } from 'utils';
var TOGGLE_BUTTON_STYLES = 'cursor-pointer flex flex-grow-0 flex-shrink-0 outline-none focus:outline-none w-16';
var TOGGLE_BUTTON_CHILD_WRAPPER_STYLES = 'px-3 md:px-4 my-auto block';
export var Toggle = function (_a) {
    var active = _a.active, children = _a.children, onClick = _a.onClick;
    return (React.createElement("button", { className: concatStyles([
            TOGGLE_BUTTON_STYLES,
            active ? 'bg-navBackground' : 'bg-transparent',
            active ? 'text-navContent' : 'text-white',
        ]), onClick: onClick },
        React.createElement("div", { className: TOGGLE_BUTTON_CHILD_WRAPPER_STYLES }, children)));
};
Toggle.displayName = 'Toggle';
