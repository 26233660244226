import React, { useState } from 'react';
// Utils
import { humanize, truncate } from 'utils';
import lowerCase from 'lodash/lowerCase';
// Components
import { Content, Input } from '..';
var ATTRIBUTES_CONTAINER_STYLES = 'flex flex-shrink-0 flex-col mb-4 box-border m-0';
var CHECKBOX_CONTAINER_STYLES = 'box-border m-0';
var Filter = function (_a) {
    var facet = _a.facet, attribute = _a.attribute, appliedFacets = _a.appliedFacets, _b = _a.maxVisibleFilters, maxVisibleFilters = _b === void 0 ? 5 : _b, transformLabel = _a.transformLabel, handleCheckBox = _a.onChange;
    var _c = useState(false), showAllOptions = _c[0], setShowAllOptions = _c[1];
    var title = humanize(transformLabel !== null && transformLabel !== void 0 ? transformLabel : attribute, true);
    var allKeys = Object.keys(facet);
    var keysLength = allKeys.length;
    var facets = showAllOptions
        ? Object.keys(facet)
        : Object.keys(facet).slice(0, maxVisibleFilters);
    var handleOnToggle = function () { return setShowAllOptions(function (prevState) { return !prevState; }); };
    return (React.createElement("div", { key: attribute, className: ATTRIBUTES_CONTAINER_STYLES },
        React.createElement(Content.Text, null, title),
        facets.map(function (value) {
            var facetValue = attribute + ":" + value;
            return (React.createElement("div", { className: CHECKBOX_CONTAINER_STYLES, key: attribute + ":" + value },
                React.createElement(Input.CheckBoxSmall, { value: value, name: value, label: "" + truncate(humanize(value, ['preferredActivities'].includes(attribute)), 20), count: facet[value], checked: appliedFacets === null || appliedFacets === void 0 ? void 0 : appliedFacets.includes(facetValue), onChange: handleCheckBox(facetValue) })));
        }),
        keysLength > maxVisibleFilters ? (React.createElement(Content.Text, { style: { marginTop: '0.5rem' } },
            React.createElement(Content.Href, { onClick: handleOnToggle, className: "cursor-pointer" },
                showAllOptions ? 'Shrink' : 'Show all',
                " ",
                lowerCase(title)))) : null));
};
export default Filter;
