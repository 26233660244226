import React from 'react';
import * as Footers from '../Footers';
var CONTAINER_STYLES = 'flex flex-col min-h-screen h-auto w-screen lg:h-screen overflow-x-hidden';
var VIEW_CONTENT_STYLES = 'flex flex-col flex-grow flex-shrink h-auto lg:flex-row lg:h-screen m-0 box-border';
var CONTENT_STYLES = 'bg-contentAreaBackground overflow-x-hidden md:overflow-y-auto w-full xl:w-1/3 m-0 box-border';
var DECORATIVE_STYLES = 'flex flex-col flex-grow w-full xl:w-2/3 m-0 box-border';
var SPLASH_STYLES = 'bg-cover bg-accent h-50-screen flex-1 md:h-70-screen lg:h-auto';
/**
 * A responsive template that displays a large splash image on
 * the right hand side of the screen.
 * @param props A configuration for the content and display of this template.
 */
export var SplashImageView = function (_a) {
    var backgroundImageSrc = _a.backgroundImageSrc, footerImageSrc = _a.footerImageSrc, children = _a.children, splashContent = _a.splashContent;
    return (React.createElement("div", { className: CONTAINER_STYLES },
        React.createElement("div", { className: VIEW_CONTENT_STYLES },
            React.createElement("div", { className: CONTENT_STYLES }, children),
            React.createElement("div", { className: DECORATIVE_STYLES },
                React.createElement("div", { className: SPLASH_STYLES, style: { backgroundImage: "url(" + backgroundImageSrc + ")" } }),
                splashContent)),
        React.createElement(Footers.Standard, { footerImageSrc: footerImageSrc })));
};
