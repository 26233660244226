import { applyMiddleware, combineReducers, createStore, compose } from 'redux'
import { ISessionState, reducer as sessionReducer } from 'session'
import LogRocket from 'logrocket'

const rootReducer = combineReducers({
  session: sessionReducer,
})

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(LogRocket.reduxMiddleware()))
)

export interface IStoreState {
  form: object
  session: ISessionState
}

export default store
