var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, useState } from 'react';
import { useSharedRef } from 'utils';
import { Input } from './Input';
var DATE_MASK = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
var PHONE_NUMBER_MASK = [
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];
var ZIP_CODE_MASK = [
    /[1-9]/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];
/**
 * Provdes easy access to a predefined mask template.
 * @param mask An identifier for predefined mask template.
 */
var getMask = function (mask) {
    switch (mask) {
        case 'phone':
            return PHONE_NUMBER_MASK;
        case 'zip':
            return ZIP_CODE_MASK;
        case 'date':
            return DATE_MASK;
    }
};
/**
 * Determines if the key entered is valid at a given index.
 * @param mask An array of mask rules.
 * @param index The current index in the string.
 * @param key The key to test
 */
var validAtIndex = function (mask, index, key) {
    return typeof mask[index] === 'string' || mask[index].test(key);
};
/**
 * Applies a mask to an input string and returns the new value with the count of matches.
 * @param inputValue The current value of an input element.
 * @param mask An array of mask rules to apply against the value.
 */
var makeMaskedValue = function (inputValue, mask) {
    var matches = 0;
    var preformattedValue = mask.reduce(function (p, m, i) {
        if (typeof m === 'string' && p[i] !== m) {
            return [p.slice(0, i), m, p.slice(i)].join('');
        }
        return p;
    }, inputValue);
    var value = mask
        .map(function (m, i) {
        if (typeof m === 'string') {
            return m;
        }
        var result = m.test(preformattedValue[i])
            ? preformattedValue[i]
            : ' ';
        if (result !== ' ') {
            matches++;
        }
        return result;
    })
        .join('');
    return { value: value, matches: matches };
};
/**
 * Determines the current index that user entered content should be inserted.
 * @param value The current value to test against the mask.
 * @param mask An array of mask rules to test.
 */
var getCursorIndex = function (value, mask) {
    var index = mask
        .map(function (m, i) {
        return (typeof m === 'string' && m === value[i]) ||
            m.test(value[i]);
    })
        .indexOf(false);
    return index > -1 ? index : mask.length;
};
export var MaskedInput = React.forwardRef(function (_a, forwardedRef) {
    var format = _a.format, onFocus = _a.onFocus, props = __rest(_a, ["format", "onFocus"]);
    var _b = useState('forward'), direction = _b[0], setDirection = _b[1];
    var inputEl = useSharedRef(null, [forwardedRef]);
    var mask = getMask(format);
    var updateInputValue = useCallback(function (value, cursorPosition) {
        if (inputEl.current) {
            inputEl.current.value = value;
            inputEl.current.setSelectionRange(cursorPosition, value.length, 'forward');
        }
    }, [inputEl]);
    var applyFormat = useCallback(function (inputVal) {
        var value = makeMaskedValue(inputVal !== null && inputVal !== void 0 ? inputVal : '', mask).value;
        if (direction === 'forward' || (inputVal === null || inputVal === void 0 ? void 0 : inputVal.length) === 0) {
            var cursor = getCursorIndex(value, mask);
            updateInputValue(value, cursor);
        }
    }, [mask, direction, updateInputValue]);
    var handleChange = function (e) {
        var _a;
        applyFormat((_a = e.currentTarget) === null || _a === void 0 ? void 0 : _a.value);
    };
    var handleFocus = function (e) {
        var _a;
        applyFormat((_a = inputEl.current) === null || _a === void 0 ? void 0 : _a.value);
        if (onFocus) {
            onFocus(e);
        }
    };
    var handleKeyDown = function (e) {
        var _a;
        var cursorPos = (_a = e.currentTarget.selectionStart) !== null && _a !== void 0 ? _a : 0;
        var key = e.key;
        setDirection(key.toLowerCase() === 'backspace' ? 'backward' : 'forward');
        if (key.length > 1) {
            return; // Bail on any sort of function or arrow key
        }
        else if (cursorPos >= mask.length ||
            !validAtIndex(mask, cursorPos, key)) {
            e.preventDefault();
        }
    };
    return (React.createElement(Input, __assign({}, props, { ref: inputEl, onChange: handleChange, onFocus: handleFocus, onKeyDown: handleKeyDown })));
});
MaskedInput.displayName = 'Form.MaskedInput';
