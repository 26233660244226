/**
 * Supported mime types for various types of file upload configurations.
 */
export var MimeTypes = {
    Documents: [
        'text/plain',
        'text/csv',
        'audio/mpeg',
        'video/mpeg',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.presentationml.template',
        'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
        'application/x-iwork-keynote-sffkey',
        'application/x-iwork-pages-sffpages',
        'application/x-iwork-numbers-sffnumbers',
    ],
    PDF: ['application/pdf'],
    Images: ['image/*'],
};
