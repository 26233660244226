import React from 'react';
import { concatStyles } from 'utils';
var BODY_STYLES = 'font-body text-sm font-normal';
var MARGIN_STYLES = 'my-2';
export var Body = function (_a) {
    var className = _a.className, children = _a.children, marginClassNames = _a.marginClassNames, style = _a.style;
    return (React.createElement("p", { className: concatStyles(BODY_STYLES, marginClassNames ? marginClassNames : MARGIN_STYLES, className), style: style }, children));
};
export var Text = function (_a) {
    var className = _a.className, children = _a.children, style = _a.style;
    return (React.createElement("span", { className: concatStyles(BODY_STYLES, className), style: style }, children));
};
