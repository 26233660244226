import React from 'react';
import { remCalc } from 'utils';
var COUNT_STYLES = 'm-auto text-primary font-body font-hairline text-center';
export var Count = function (_a) {
    var margin = _a.margin, fontSize = _a.fontSize, children = _a.children;
    var COUNT_INLINE_STYLES = {
        margin: margin !== null && margin !== void 0 ? margin : 'auto',
        fontSize: fontSize !== null && fontSize !== void 0 ? fontSize : remCalc(28),
    };
    return (React.createElement("p", { style: COUNT_INLINE_STYLES, className: COUNT_STYLES }, children));
};
Count.displayName = 'Content.Count';
