var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { FileUploadStatus, } from './FileDropProps';
import { MimeTypes } from './MimeTypes';
import { FileUpload } from './FileUpload';
var normalize = function (s) {
    return s
        .split('.')
        .map(function (s) { return s.replace(/\W+/g, '_'); })
        .join('.');
};
/**
 * Sorts previews by position whilst filtering out deleted previews.
 * @param previews A list of previews / files to sort.
 */
var filterPreviews = function (previews) {
    return previews
        .filter(function (p) { return p.status !== FileUploadStatus.Deleted; })
        .map(function (p) {
        var _a;
        return (__assign(__assign({}, p), { id: normalize(p.id), name: normalize((_a = p.name) !== null && _a !== void 0 ? _a : p.id) }));
    })
        .sort(function (a, b) {
        var _a, _b, _c, _d;
        return ((_a = a.position) !== null && _a !== void 0 ? _a : 0) < ((_b = b.position) !== null && _b !== void 0 ? _b : 0)
            ? -1
            : ((_c = a.position) !== null && _c !== void 0 ? _c : 0) > ((_d = b.position) !== null && _d !== void 0 ? _d : 0)
                ? 1
                : 0;
    });
};
export var useNormalizedUploads = function (_a) {
    var accept = _a.accept, onUpdate = _a.onUpdate, previews = _a.previews, getEndpointsForFilenames = _a.getEndpointsForFilenames, maxFiles = _a.maxFiles, externalDeleteHandler = _a.onDelete, externalUploadFinishedHandler = _a.onUpload, externalErrorHandler = _a.onError;
    // Sorted and filtered incomming previews...
    var renderablePreviews = filterPreviews(previews);
    // Track any pending uploads...
    var _b = useState({}), uploads = _b[0], setUploads = _b[1];
    var _c = useState(null), progressUpdate = _c[0], setProgressUpdate = _c[1];
    var _d = useState({}), trackedProgress = _d[0], setTrackedProgress = _d[1];
    useEffect(function () {
        var _a;
        if (!progressUpdate) {
            return;
        }
        var existingUpdate = trackedProgress[progressUpdate.id];
        if ((existingUpdate === null || existingUpdate === void 0 ? void 0 : existingUpdate.progress) !== progressUpdate.progress) {
            setTrackedProgress(__assign(__assign({}, trackedProgress), (_a = {}, _a[progressUpdate.id] = progressUpdate, _a)));
        }
    }, [uploads, progressUpdate, trackedProgress, setTrackedProgress]);
    /**
     * Fires the on update callback supplied by the component.
     * @param newPreviews A map of normalized previews based on local state.
     */
    var savePreviews = useCallback(function (newPreviews) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onUpdate(filterPreviews(newPreviews))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [onUpdate]);
    var handleAdditionalFile = function (newPreviews) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, savePreviews(__spreadArrays(renderablePreviews, newPreviews))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSort = useCallback(function (dragId, _dragIndex, hoverIndex) { return __awaiter(void 0, void 0, void 0, function () {
        var currentPreview, updates, repositioned;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    currentPreview = renderablePreviews.filter(function (p) { return p.id === dragId; })[0];
                    if (!currentPreview) {
                        return [2 /*return*/];
                    }
                    updates = __spreadArrays(renderablePreviews);
                    updates.splice(currentPreview.position, 1);
                    updates.splice(hoverIndex, 0, renderablePreviews[currentPreview.position]);
                    repositioned = updates.map(function (u, i) { return (__assign(__assign({}, u), { position: i })); });
                    return [4 /*yield*/, savePreviews(repositioned)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [renderablePreviews, savePreviews]);
    var handleFinishSort = function () {
        console.log("Sorting has finished... maybe we don't need this anymore?");
    };
    var handleDeletedFile = function (preview) { return __awaiter(void 0, void 0, void 0, function () {
        var updates;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    updates = __spreadArrays(renderablePreviews.filter(function (p) { return p.id !== preview.id; }), [
                        __assign(__assign({}, preview), { status: FileUploadStatus.Deleted }),
                    ]);
                    return [4 /*yield*/, savePreviews(updates)];
                case 1:
                    _a.sent();
                    if (!externalDeleteHandler) return [3 /*break*/, 3];
                    return [4 /*yield*/, externalDeleteHandler(preview)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleFileKeys = function (keys) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getEndpointsForFilenames(keys)
                // Initialize dropzone...
            ];
            case 1: return [2 /*return*/, _a.sent()
                // Initialize dropzone...
            ];
        }
    }); }); };
    // Initialize dropzone...
    var dropzone = useDropzone({
        maxFiles: maxFiles,
        accept: accept !== null && accept !== void 0 ? accept : MimeTypes.Images,
        onDrop: function (acceptedFiles) { return __awaiter(void 0, void 0, void 0, function () {
            var uniqueKey, newFiles, signedUrls, processedFiles, uploadableFiles;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (maxFiles && previews.length >= maxFiles) {
                            return [2 /*return*/];
                        }
                        uniqueKey = ("" + new Date().getTime()).slice(9, 13);
                        newFiles = acceptedFiles.map(function (file, index) {
                            return {
                                id: uniqueKey + "_" + normalize(file.name),
                                file: file,
                                position: previews.length + index,
                                status: FileUploadStatus.Pending,
                            };
                        });
                        return [4 /*yield*/, handleFileKeys(newFiles.map(function (f) { var _a, _b; return ({ name: f.id, contentType: (_b = (_a = f.file) === null || _a === void 0 ? void 0 : _a.type) !== null && _b !== void 0 ? _b : '' }); }))];
                    case 1:
                        signedUrls = (_a.sent()) || [];
                        processedFiles = newFiles.map(function (f) {
                            var _a;
                            var uploadUrl = ((_a = (signedUrls || []).find(function (url) {
                                return new RegExp(f.id + "$").test(url.id);
                            })) !== null && _a !== void 0 ? _a : {}).uploadUrl;
                            return __assign(__assign({}, f), { uploadUrl: uploadUrl });
                        });
                        processedFiles
                            .filter(function (c) { return !c.file || !c.uploadUrl; })
                            .map(function (c) { return externalErrorHandler === null || externalErrorHandler === void 0 ? void 0 : externalErrorHandler(new Error("Could not request a signed url for file \"" + c.id + "\"")); });
                        uploadableFiles = processedFiles.filter(function (c) { return typeof c.uploadUrl === 'string' && c.file; });
                        setUploads(uploadableFiles.reduce(function (p, c) {
                            var _a;
                            return (__assign(__assign({}, uploads), (_a = {}, _a[c.id] = FileUpload(c.id, c.file, c.uploadUrl, function (_, status, fileProgress) {
                                setProgressUpdate({ id: c.id, progress: fileProgress, status: status });
                                if (fileProgress === 100 &&
                                    status === FileUploadStatus.Available &&
                                    externalUploadFinishedHandler) {
                                    externalUploadFinishedHandler(c);
                                }
                            }), _a)));
                        }, {}));
                        handleAdditionalFile(__spreadArrays(uploadableFiles));
                        return [2 /*return*/];
                }
            });
        }); },
    });
    var progressForUpload = function (id) {
        var _a, _b;
        return (_b = (_a = trackedProgress[id]) === null || _a === void 0 ? void 0 : _a.progress) !== null && _b !== void 0 ? _b : 100;
    };
    return {
        renderablePreviews: renderablePreviews,
        dropzone: dropzone,
        handleAdditionalFile: handleAdditionalFile,
        handleSort: handleSort,
        handleFinishSort: handleFinishSort,
        handleDeletedFile: handleDeletedFile,
        handleFileKeys: handleFileKeys,
        progressForUpload: progressForUpload,
    };
};
