var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { transparentize } from 'polished';
import * as React from 'react';
import styled from '../styled-components';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  display: flex;\n  font-family: ", ";\n  flex-direction: column;\n  position: absolute;\n  top: 100%;\n  left: 0;\n  min-width: 100%;\n  border-radius: ", ";\n  border: 1px solid ", ";\n  box-shadow: 5px 5px 10px\n    ", ";\n  padding: 0;\n  margin: 4px 0 0 0;\n  z-index: 1;\n  max-height: 400px;\n  overflow-y: auto;\n"], ["\n  background: ", ";\n  display: flex;\n  font-family: ", ";\n  flex-direction: column;\n  position: absolute;\n  top: 100%;\n  left: 0;\n  min-width: 100%;\n  border-radius: ", ";\n  border: 1px solid ", ";\n  box-shadow: 5px 5px 10px\n    ", ";\n  padding: 0;\n  margin: 4px 0 0 0;\n  z-index: 1;\n  max-height: 400px;\n  overflow-y: auto;\n"])), function (p) { return p.theme.colorContentAreaBackground; }, function (p) { return p.theme.fontFamilyBody; }, function (p) { return p.theme.borderRadius; }, function (p) { return p.theme.colorContentAreaBorderSelected; }, function (p) { return transparentize(0.75)(p.theme.colorContentAreaBorderSelected); });
var Suggestion = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-shrink: 0;\n  list-style: none;\n  padding: 0rem;\n  font-family: ", ";\n  border-bottom: 1px solid\n    ", ";\n  &:hover {\n    background: ", ";\n  }\n"], ["\n  display: flex;\n  flex-shrink: 0;\n  list-style: none;\n  padding: 0rem;\n  font-family: ", ";\n  border-bottom: 1px solid\n    ", ";\n  &:hover {\n    background: ", ";\n  }\n"])), function (p) { return p.theme.fontFamilyBody; }, function (p) { return transparentize(0.7)(p.theme.colorContentAreaBorderSelected); }, function (p) { return transparentize(0.9)(p.theme.colorPrimary); });
var Button = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border: 0;\n  background: transparent;\n  display: flex;\n  cursor: pointer;\n  padding: 0.5rem;\n  flex-shrink: 1;\n  flex-grow: 1;\n  overflow: hidden;\n"], ["\n  border: 0;\n  background: transparent;\n  display: flex;\n  cursor: pointer;\n  padding: 0.5rem;\n  flex-shrink: 1;\n  flex-grow: 1;\n  overflow: hidden;\n"])));
var makeOnClick = function (option, handler) {
    return function (_) {
        return handler && handler(option);
    };
};
var DetailView = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  flex-direction: row;\n"], ["\n  position: relative;\n  display: flex;\n  flex-direction: row;\n"])));
var DetailContents = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  margin: 0;\n  padding: 0.1rem;\n  flex-direction: column;\n  text-align: left;\n"], ["\n  display: flex;\n  margin: 0;\n  padding: 0.1rem;\n  flex-direction: column;\n  text-align: left;\n"])));
var DetailHeader = styled.strong(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-weight: 600;\n  color: ", ";\n  text-transform: uppercase;\n  font-size: 0.75rem;\n  margin: 0;\n"], ["\n  font-weight: 600;\n  color: ", ";\n  text-transform: uppercase;\n  font-size: 0.75rem;\n  margin: 0;\n"])), function (p) { return p.theme.colorPrimary; });
var DetailDescription = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin: 0;\n  padding: 0;\n  display: flex;\n  flex-shrink: 1;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n"], ["\n  margin: 0;\n  padding: 0;\n  display: flex;\n  flex-shrink: 1;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n"])));
export var SuggestionDetailView = function (props) { return (React.createElement(DetailView, null,
    props.icon ? React.createElement("img", { src: props.icon, alt: "" }) : null,
    React.createElement(DetailContents, null,
        props.heading ? React.createElement(DetailHeader, null, props.heading) : null,
        props.description ? (React.createElement(DetailDescription, null, props.description)) : null))); };
export var AutocompleteSuggestion = function (p) { return (React.createElement(Suggestion, null,
    React.createElement(Button, { onClick: p.onClick, type: "button" }, p.displayName))); };
export var CustomAutocomplete = function (_a) {
    var children = _a.children;
    return React.createElement(Container, null, children());
};
export var Autocomplete = function (_a) {
    var suggestions = _a.suggestions, onSelect = _a.onSelect;
    return (React.createElement(Container, null, suggestions.map(function (suggestion, index) { return (React.createElement(Suggestion, { key: "suggestion." + index },
        React.createElement(Button, { onClick: makeOnClick(suggestion, onSelect) }, suggestion.view || suggestion.displayName))); })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
