import React from 'react';
import { Link } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export var ReturnLink = function (_a) {
    var to = _a.to, children = _a.children;
    return (React.createElement("p", { className: "flex box-border m-0" },
        React.createElement("strong", { className: "box-border m-0 mr-2" },
            React.createElement(FontAwesomeIcon, { icon: faChevronLeft })),
        React.createElement(Link, { to: to, className: "text-actionable font-body" }, children)));
};
