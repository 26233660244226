import * as React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FieldContainer } from '../Input';
var Captcha = function (_a) {
    var onChange = _a.onChange, test = _a.test;
    return (React.createElement(FieldContainer, { as: "div" },
        React.createElement(ReCAPTCHA, { sitekey: process.env.REACT_APP_RECAPTCHA_KEY ||
                '6LfpWKIUAAAAAP79GcsYo-2ge-xWKICgTZvU5XW8', onChange: onChange })));
};
export default Captcha;
