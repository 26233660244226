import React from 'react';
import * as Card from '../Card';
var INVITATION_CARD_STYLE = 'flex-wrap flex-col sm:flex-row px-2 py-2 my-2';
var ATTENDEE_STYLE = 'w-full md:w-1/2 m-0 box-border p-1';
export var Invitations = function (_a) {
    var attendeeList = _a.attendeeList, currentUserProfileId = _a.currentUserProfileId, invitations = _a.invitations, handleSelectInvitation = _a.onSelectInvitation, handleSelectCurrentUser = _a.onSelectCurrentUser, showStatus = _a.showStatus;
    var handleClick = function (invitation) {
        return function (e) {
            e.preventDefault();
            if (invitation.profileId === currentUserProfileId &&
                handleSelectCurrentUser) {
                handleSelectCurrentUser(invitation);
            }
            if (invitation.profileId !== currentUserProfileId &&
                handleSelectInvitation) {
                handleSelectInvitation(invitation);
            }
        };
    };
    return (React.createElement("div", { className: "mb-4" },
        React.createElement(Card.View, { internal: true, className: INVITATION_CARD_STYLE }, invitations
            ? invitations.map(function (i) { return (React.createElement("div", { key: i.id, className: ATTENDEE_STYLE }, attendeeList ? (React.createElement(Card.InvitationAttendee, { invite: i, className: "px-2 py-3", onClick: handleClick(i), showStatus: showStatus })) : (React.createElement(Card.InvitationProfile, { invite: i, className: "px-2 py-3", onClick: handleClick(i), showStatus: showStatus })))); })
            : null)));
};
