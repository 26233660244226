var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useField } from 'formik';
import React from 'react';
import { Label, RequiredIcon } from '../Form';
import { Error } from './Error';
import { FieldContainer, InputWrapper } from './FieldContainer';
import { Icon } from './Icon';
/**
 * A simple non-context based field renderer for use with Formik
 * when utilizing the Hooks API. Most fields will be migrated to
 * this format as it's much easier to maintain and understand.
 *
 * @param props Configuration for this component.
 */
export var Field = function (_a) {
    var children = _a.children, icon = _a.icon, label = _a.label, name = _a.name, required = _a.required, ref = _a.ref, flex = __rest(_a, ["children", "icon", "label", "name", "required", "ref"]);
    // eslint-disable-next-line
    var _b = useField({ name: name }), _ = _b[0], meta = _b[1];
    return (React.createElement(FieldContainer, __assign({}, flex, { my: flex.my || 2, mx: flex.mx || 0 }),
        label && (React.createElement(Label, { htmlFor: name },
            label,
            required && React.createElement(RequiredIcon, null))),
        React.createElement(InputWrapper, null,
            children,
            icon ? React.createElement(Icon, { icon: icon }) : React.createElement("span", null)),
        meta.error && meta.touched ? React.createElement(Error, null, meta.error) : null));
};
