var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons/faAsterisk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import styled, { remCalc } from '../styled-components';
var RequiredFormat = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  margin: auto 4px;\n  display: flex;\n  flex-grow: 0;\n\n  & > * {\n    margin: auto;\n  }\n"], ["\n  color: ", ";\n  font-size: ", ";\n  margin: auto 4px;\n  display: flex;\n  flex-grow: 0;\n\n  & > * {\n    margin: auto;\n  }\n"])), function (p) { return p.theme.colorAccent; }, remCalc(10));
export var RequiredIcon = function () { return (React.createElement(RequiredFormat, null,
    React.createElement(FontAwesomeIcon, { icon: faAsterisk, size: "xs" }))); };
var templateObject_1;
