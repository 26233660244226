var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import styled, { remCalc } from '../styled-components';
export var DecorativeRadio = styled.p(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  background: ", ";\n  cursor: pointer;\n  display: flex;\n  flex: 0 0 auto;\n  height: ", ";\n  width: ", ";\n  border: 1px solid ", ";\n  border-radius: ", ";\n  position: relative;\n  margin: 0;\n  padding: 0;\n\n  & > input {\n    display: flex;\n    flex: 1 2 auto;\n    cursor: pointer;\n    position: absolute;\n    opacity: 0;\n    margin: auto;\n    top: 0;\n    left: 0;\n    height: 100%;\n    width: 100%;\n  }\n"], ["\n  align-items: center;\n  background: ", ";\n  cursor: pointer;\n  display: flex;\n  flex: 0 0 auto;\n  height: ", ";\n  width: ", ";\n  border: 1px solid ", ";\n  border-radius: ", ";\n  position: relative;\n  margin: 0;\n  padding: 0;\n\n  & > input {\n    display: flex;\n    flex: 1 2 auto;\n    cursor: pointer;\n    position: absolute;\n    opacity: 0;\n    margin: auto;\n    top: 0;\n    left: 0;\n    height: 100%;\n    width: 100%;\n  }\n"])), function (p) { return p.theme.colorBodyInverted; }, remCalc(22), remCalc(22), function (p) { return p.theme.colorContentAreaBorder; }, remCalc(11));
export var DecorativeRadioSmall = styled(DecorativeRadio)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: ", ";\n  width: ", ";\n"], ["\n  height: ", ";\n  width: ", ";\n"])), remCalc(11), remCalc(11));
export var Centered = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  cursor: pointer;\n  display: flex;\n  margin: auto;\n  pointer-events: none;\n"], ["\n  cursor: pointer;\n  display: flex;\n  margin: auto;\n  pointer-events: none;\n"])));
export var DecorativeRadioIndicator = function () { return (React.createElement(Centered, null,
    React.createElement(FontAwesomeIcon, { icon: faCircle }))); };
var templateObject_1, templateObject_2, templateObject_3;
