import React, { useState } from 'react';
import * as Content from '../Content';
import * as Form from '../Form';
import * as Input from '../Input';
import { View } from './View';
var FLEX_COLUMN = 'flex flex-col';
var CONTAINER_STYLES = FLEX_COLUMN + " p-8 pt-0";
var LABEL_CONTAINER_STYLES = FLEX_COLUMN + " w-full mb-2";
export var Dialog = function (props) {
    var _a, _b, _c;
    var _d = useState(null), message = _d[0], setMessage = _d[1];
    var handleConfirm = function () {
        return props.onConfirm ? props.onConfirm(message) : null;
    };
    var handleChange = function (e) {
        setMessage(e.target.value);
    };
    return (React.createElement(View, { onDismiss: props.onDismiss, loading: props.loading },
        React.createElement("div", { className: CONTAINER_STYLES },
            React.createElement(Content.HeadingSmall, null, props.title),
            React.createElement(Content.Body, null, props.message),
            props.allowMessage ? (React.createElement("div", { className: LABEL_CONTAINER_STYLES },
                React.createElement(Form.Label, null, (_a = props.messageLabel) !== null && _a !== void 0 ? _a : 'Add an optional message:'),
                React.createElement(Input.TextArea, { value: message !== null && message !== void 0 ? message : '', onChange: handleChange }))) : null,
            React.createElement(Form.ButtonGroup, { direction: "row" },
                React.createElement(Form.Button, { onClick: props.onDismiss },
                    React.createElement("span", null, (_b = props.cancelLabel) !== null && _b !== void 0 ? _b : 'Cancel')),
                props.onConfirm ? (React.createElement(Form.Button, { onClick: handleConfirm, appearance: props.destructive ? 'destructive' : 'actionable' },
                    React.createElement("span", null, (_c = props.confirmLabel) !== null && _c !== void 0 ? _c : 'Confirm'))) : null))));
};
