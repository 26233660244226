var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
// utils
import { remCalc, concatStyles } from 'utils';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Form from '../Form';
import * as Indicators from '../Indicators';
var DEFAULT_MARGINS = 'my-1';
export var ActionButton = function (_a) {
    var disableIcon = _a.disableIcon, disabled = _a.disabled, fontSize = _a.fontSize, icon = _a.icon, label = _a.label, loading = _a.loading, style = _a.style, handleClick = _a.onClick, className = _a.className, type = _a.type, _b = _a.appearance, appearance = _b === void 0 ? 'actionable' : _b, _c = _a.align, align = _c === void 0 ? 'stretch' : _c, form = _a.form;
    var fontSizeStyle = { fontSize: fontSize ? remCalc(fontSize) : 'inherit' };
    var labelStyles = __assign({ marginRight: disableIcon === true ? 'auto' : 16 }, fontSizeStyle);
    return (React.createElement(Form.Button, { appearance: appearance, align: align, onClick: handleClick, disabled: disabled || loading, type: type, style: style, className: concatStyles([className, DEFAULT_MARGINS]), form: form },
        React.createElement("div", { className: "flex w-full" },
            React.createElement("span", { className: "ml-auto whitespace-no-wrap", style: labelStyles }, label),
            disableIcon === true || loading ? null : (React.createElement("span", { className: "mr-auto", style: fontSizeStyle },
                React.createElement(FontAwesomeIcon, { icon: icon || faChevronRight }))),
            loading ? (React.createElement("div", { className: "flex mr-auto" },
                React.createElement(Indicators.Spinner, null))) : null)));
};
ActionButton.displayName = 'Content.ActionButton';
