var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
// utils
import debounce from 'lodash.debounce';
import { concatStyles, remCalc, wait } from 'utils';
// ui
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Indicator from '../Indicators';
import { TextField } from './TextField';
import { useEffect } from 'react';
var CONTAINER_STYLES = 'flex flex-grow flex-col w-full relative font-body';
var RESULT_STYLES = 'bg-white flex flex-col border-2 border-t-0 shadow rounded-b overflow-y-auto w-full ';
var RESULT_ITEM_STYLES = 'border-b border-contentAreaBorder text-left text-sm';
var getPositionForMethod = function (push) {
    return push ? 'relative' : 'absolute mt-0 top-100 z-20';
};
var FIELD_STYLES = 'flex flex-grow bg-white w-auto h-auto mb-auto relative w-full p-2 rounded focus:outline-none overflow-hidden';
var ACTIVE_FIELD_STYLES = 'border border-actionable shadow';
var INACTIVE_FIELD_STYLES = 'border border-contentAreaBorder';
var ERROR_FIELD_STYLES = 'border border-error';
var getFieldAppearance = function (active, error) {
    return active
        ? ACTIVE_FIELD_STYLES
        : error
            ? ERROR_FIELD_STYLES
            : INACTIVE_FIELD_STYLES;
};
var SEARCH_FIELD_WRAPPER_STYLES = 'sticky top-0 z-10 text-primary min-w-0 max-w-full p-2';
var LABEL_STYLES = 'flex my-auto mx-0 truncate';
var getLabelOpacityStyle = function (active) { return (active ? '' : 'opacity-25'); };
var OVERLAY_STYLES = 'fixed z-10 h-screen w-screen top-0 left-0';
var ICON_WRAP_STYLES = 'absolute m-0 p-3 flex flex-grow text-lg text-white font-body inset-y-0 right-0';
var ACTIVE_ICON_WRAP_STYLES = 'bg-actionable';
var INACTIVE_ICON_WRAP_STYLES = 'bg-primary';
var ERROR_ICON_WRAP_STYLES = 'bg-error';
var ICON_STYLES = 'm-auto';
var getIconWrapAppearance = function (active, error) {
    return active
        ? ACTIVE_ICON_WRAP_STYLES
        : error
            ? ERROR_ICON_WRAP_STYLES
            : INACTIVE_ICON_WRAP_STYLES;
};
export var Dropdown = React.forwardRef(function (_a, forwardRef) {
    var className = _a.className, name = _a.name, pushContent = _a.pushContent, hideSearch = _a.hideSearch, value = _a.value, children = _a.children, placeholder = _a.placeholder, onDismiss = _a.onDismiss, onAppear = _a.onAppear, onSearch = _a.onSearch, loading = _a.loading, style = _a.style, error = _a.error, externalActive = _a.active;
    var _b = useState(false), active = _b[0], setActive = _b[1];
    var _c = useState(''), searchValue = _c[0], setSearchValue = _c[1];
    var updateSearch = debounce(function (updatedVal) {
        setSearchValue(updatedVal);
        if (onSearch) {
            onSearch(updatedVal);
        }
    }, 300, { leading: false, trailing: true });
    var onClick = function (e) {
        e.preventDefault();
        setActive(!active);
    };
    var onChange = function (e) {
        return updateSearch(e.target.value);
    };
    var dismiss = function () {
        setActive(false);
        setSearchValue('');
        if (onDismiss) {
            onDismiss();
        }
    };
    var delayedDismiss = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, wait(0.2)];
                case 1:
                    _a.sent();
                    dismiss();
                    return [2 /*return*/];
            }
        });
    }); };
    var hasValue = typeof value === 'string' && value.length > 0;
    var isActive = externalActive !== null && externalActive !== void 0 ? externalActive : active;
    useEffect(function () {
        if (isActive) {
            onAppear === null || onAppear === void 0 ? void 0 : onAppear();
        }
    }, [onAppear, isActive]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: concatStyles([CONTAINER_STYLES, className]), style: style },
            React.createElement("button", { onClick: onClick, className: concatStyles([
                    FIELD_STYLES,
                    getFieldAppearance(isActive, error),
                ]) },
                React.createElement("label", { className: concatStyles([
                        LABEL_STYLES,
                        getLabelOpacityStyle(hasValue),
                    ]) }, hasValue ? value : placeholder),
                React.createElement("p", { className: concatStyles([
                        ICON_WRAP_STYLES,
                        getIconWrapAppearance(isActive, error),
                    ]) },
                    React.createElement(FontAwesomeIcon, { className: ICON_STYLES, icon: faChevronDown }))),
            React.createElement("input", { type: "hidden", name: name, ref: forwardRef }),
            isActive ? (React.createElement("ul", { className: concatStyles([
                    RESULT_STYLES,
                    getPositionForMethod(pushContent),
                ]), style: { maxHeight: remCalc(256) } },
                hideSearch ? null : (React.createElement("li", { className: SEARCH_FIELD_WRAPPER_STYLES },
                    React.createElement(TextField, { name: name + ".search", icon: faSearch, type: "search", onChange: onChange }),
                    loading ? React.createElement(Indicator.Spinner, { className: "mr-2" }) : null)),
                children(searchValue, dismiss).map(function (child, index) { return (React.createElement("li", { className: RESULT_ITEM_STYLES, onClick: delayedDismiss, key: "dropdown" + name + "Option" + index }, child)); }))) : null),
        !pushContent && isActive ? (React.createElement("div", { className: OVERLAY_STYLES, onClick: dismiss })) : null));
});
Dropdown.displayName = 'Form.Dropdown';
