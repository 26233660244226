var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import { Field, getErrorMessage } from './Field';
import { Input } from './Input';
import { DateSelector } from './DateSelector';
var hasError = function (error) { return (error !== null && error !== void 0 ? error : '').length > 0; };
var getPaddingForField = function (hasIcon, searchField) {
    return hasIcon ? (searchField ? 'pl-8' : 'pr-8') : '';
};
var DATE_SELECTOR_STYLES = 'bg-white p-1 rounded shadow absolute top-100 inset-x-0 flex z-20';
var DISMISS_STYLES = 'bg-white opacity-0 z-10 fixed h-screen w-screen top-0 left-0';
export var DateField = React.forwardRef(function (_a, forwardedRef) {
    var name = _a.name, placeholder = _a.placeholder, className = _a.className, style = _a.style, disabled = _a.disabled, type = _a.type, errorMessage = _a.errorMessage, value = _a.value, errors = _a.errors, format = _a.format, onChange = _a.onChange, onBlur = _a.onBlur, onFocus = _a.onFocus, onDateSelected = _a.onDateSelected, fieldProps = __rest(_a, ["name", "placeholder", "className", "style", "disabled", "type", "errorMessage", "value", "errors", "format", "onChange", "onBlur", "onFocus", "onDateSelected"]);
    var _b = useState(false), focused = _b[0], setFocused = _b[1];
    var determinedErrorMessage = getErrorMessage({
        name: name,
        errorMessage: errorMessage,
        errors: errors,
    });
    var searchField = type === 'search';
    var hasIcon = typeof fieldProps.icon !== 'undefined';
    var handleFocus = function (focus) { return function () { return setFocused(focus); }; };
    var handleDateSelect = function (d) {
        if (onDateSelected) {
            onDateSelected(d);
        }
        setFocused(false);
    };
    return (React.createElement(Field, __assign({ name: name, className: className, style: style, errorMessage: determinedErrorMessage, searchField: searchField }, fieldProps),
        React.createElement(Input, { name: name, placeholder: placeholder, disabled: disabled, error: hasError(determinedErrorMessage), type: type, ref: forwardedRef, value: value, onChange: onChange, className: getPaddingForField(hasIcon, searchField), onFocus: handleFocus(true) }),
        focused ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: DATE_SELECTOR_STYLES },
                React.createElement(DateSelector, { onDateSelected: handleDateSelect, initialDate: value, selectedDate: value, dateFormat: "MM/dd/yyyy", useFormattedValue: true })),
            React.createElement("button", { className: DISMISS_STYLES, onClick: handleFocus(false) }))) : null));
});
DateField.displayName = 'Form.DateField';
