import React from 'react';
import { remCalc, concatStyles } from 'utils';
var colorForBubbleStyle = function (style) {
    switch (style) {
        case 'warning':
            return 'bg-accent';
        case 'destructive':
            return 'bg-error';
        default:
            return 'bg-primary';
    }
};
var textColorForBubbleStyle = function (style) {
    switch (style) {
        case 'warning':
            return 'text-body';
        default:
            return 'text-contentAreaBackground';
    }
};
var BUBBLE_STYLES = 'flex py-1 px-2 font-body uppercase rounded rounded-none cursor-pointer';
export var Bubble = function (_a) {
    var bubbleStyle = _a.bubbleStyle, marginY = _a.marginY, children = _a.children;
    var BACKGROUND_COLOR_STYLES = colorForBubbleStyle(bubbleStyle);
    var TEXT_COLOR_STYLES = textColorForBubbleStyle(bubbleStyle);
    var BUBBLE_INLINE_STYLES = {
        fontSize: remCalc(8),
        margin: (marginY !== null && marginY !== void 0 ? marginY : 'auto') + " " + remCalc(5) + " " + (marginY !== null && marginY !== void 0 ? marginY : 'auto') + " 0",
    };
    return (React.createElement("span", { style: BUBBLE_INLINE_STYLES, className: concatStyles([
            BUBBLE_STYLES,
            BACKGROUND_COLOR_STYLES,
            TEXT_COLOR_STYLES,
        ]) }, children));
};
Bubble.displayName = 'Content.Bubble';
