import { DateTime } from 'luxon';
import React from 'react';
import { AutocompleteSuggestion, SuggestionDetailView } from './Autocomplete';
import { Dropdown } from './Dropdown';
import { flat } from 'utils';
var simpleTime = function (time) { return parseInt(time.toFormat('Hmm'), 0); };
/**
 * Persist the hours and minutes from one moment to another.
 * @param from The moment to utilize the hour and minute time from.
 * @param to The moment to apply the hour and minute time to.
 */
export var mapTime = function (from, to) {
    return (to || DateTime.local())
        .set({ hour: (from || DateTime.local()).hour })
        .set({ minute: (from || DateTime.local()).minute });
};
export var TimeSelect = function (props) {
    return (React.createElement(Dropdown, { value: props.value, placeholder: props.placeholder, onDismiss: props.onDismiss, pushContent: props.pushContent, loading: props.loading }, function (search, dismiss) { return (React.createElement(React.Fragment, null, flat(new Array(24).fill('').map(function (_, hour) {
        return new Array(4)
            .fill('')
            .map(function (v, i) {
            return DateTime.local()
                .set({ hour: hour })
                .set({ minute: i * 15 });
        })
            .filter(function (time) {
            return simpleTime(time) <
                simpleTime(props.maxTime || DateTime.local().set({ hour: 23 }));
        })
            .filter(function (time) {
            return simpleTime(time) >
                simpleTime(props.minTime || DateTime.local().set({ hour: 5 }));
        })
            .filter(function (time) {
            return time
                .toFormat('h:mm a')
                .toLowerCase()
                .includes(search.toLowerCase());
        })
            .map(function (time, i) {
            var onClick = function (e) {
                e.stopPropagation();
                props.onSelect(time.toISO());
                dismiss();
            };
            return (React.createElement(AutocompleteSuggestion, { key: props.name + "-TimeSelectInterval" + time.toFormat('H:mm'), displayName: React.createElement(SuggestionDetailView, { heading: time.toFormat('h:mm a') }), onClick: onClick }));
        });
    })))); }));
};
