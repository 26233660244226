import React, { useState } from 'react';
import { concatStyles } from 'utils';
import * as Card from '../Card';
import { ColorOption } from './ColorOption';
import { Indicator } from './Indicator';
var PICKER_VIEW_STYLES = 'absolute flex z-30';
/**
 * Renders a color picker dialog.
 * @param props Any confiugration for this component.
 */
export var Picker = function (_a) {
    var colors = _a.colors, disabled = _a.disabled, onSelectColor = _a.onSelectColor, selectedColor = _a.selectedColor, title = _a.title, size = _a.size, className = _a.className;
    var _b = useState(false), active = _b[0], setActive = _b[1];
    var toggleSelector = function () { return setActive(!active); };
    var selectColor = function (color) {
        return function () {
            if (onSelectColor) {
                onSelectColor(color);
            }
        };
    };
    return (React.createElement(Indicator, { size: size || 32, color: selectedColor, onClick: toggleSelector, disabled: disabled }, active ? (React.createElement("div", { className: concatStyles([PICKER_VIEW_STYLES, className !== null && className !== void 0 ? className : 'w-64']), style: { top: '-4px', left: '-4px' } },
        React.createElement(Card.View, { className: "px-2 pt-2 flex-col" },
            title ? React.createElement(Card.Title, null, title) : null,
            React.createElement("ul", { className: "flex flex-wrap flex-grow m-0 p-0" }, colors.map(function (color) { return (React.createElement("li", { className: "mb-2 mr-2 w-1/6 p-0 list-none" },
                React.createElement(ColorOption, { key: color, color: color, selected: selectedColor === color, onClick: selectColor(color) }))); }))))) : null));
};
