var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { StyleableLink } from '../Navigation';
import styled from '../styled-components';
export var LinkVariant;
(function (LinkVariant) {
    LinkVariant[LinkVariant["Default"] = 0] = "Default";
    LinkVariant[LinkVariant["Footer"] = 1] = "Footer";
})(LinkVariant || (LinkVariant = {}));
export var colorForLinkVariant = function (linkVariant, theme) {
    switch (linkVariant) {
        case LinkVariant.Footer:
            return theme.colorBodyInverted;
        default:
            return theme.colorActionable;
    }
};
export var Link = styled(StyleableLink)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (p) { return colorForLinkVariant(p.linkVariant, p.theme); });
var templateObject_1;
