import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
// Styles
var CONTAINER_STYLES = 'flex flex-col w-full mt-1 mr-0 mb-3 ml-0';
var COLLAPSABLE_STYLES = 'flex flex-row flex-grow flex-shrink-0 py-2 px-3 border-none rounded text-contentAreaBackground bg-primary';
var LABEL_STYLES = 'text-contentAreaBackground text-sm text-left font-heading font-semibold uppercase my-auto mx-0 flex-auto';
var INDICATOR_STYLES = 'text-contentAreaBackground text-base my-auto mr-0 ml-2 items-center';
var INDICATOR_INLINE_STYLES = { flex: '0 0 auto' };
export var Collapse = function (props) {
    var _a = useState(props.initiallyOpen || false), isOpen = _a[0], setOpen = _a[1];
    var handleClick = function (e) {
        setOpen(!isOpen);
        e.preventDefault();
        e.stopPropagation();
    };
    return (React.createElement("div", { className: CONTAINER_STYLES },
        React.createElement("button", { className: COLLAPSABLE_STYLES, onClick: handleClick },
            React.createElement("h3", { className: LABEL_STYLES }, props.label),
            React.createElement("div", { style: INDICATOR_INLINE_STYLES, className: INDICATOR_STYLES },
                React.createElement(FontAwesomeIcon, { icon: isOpen ? faChevronUp : faChevronDown }))),
        isOpen ? props.children : null));
};
Collapse.displayName = 'Content.Collapse';
