import React from 'react';
import { concatStyles } from 'utils';
import * as Content from '../Content';
var CONTAINER_STYLES = 'flex w-full m-0 box-border';
var AVAILABLE_PAGE_STYLES = 'text-actionable hover:bg-actionable hover:bg-opacity-25 rounded';
var PAGINATION_WRAPPER_STYLES = 'flex box-border p-2 m-0 mx-auto my-0';
var PAGE_STYLES = 'p-2 m-0 box-border';
var CURRENT_PAGE_STYLES = 'bg-actionable text-white rounded';
var TRUNCATION_STYLES = 'my-auto flex opacity-25';
var PageNumber = function (_a) {
    var page = _a.page, handlePage = _a.onClick;
    var handleClick = function (e) {
        e.preventDefault();
        handlePage(page);
    };
    return (React.createElement("li", { className: "flex", key: "pageButton" + page },
        React.createElement("button", { className: concatStyles([PAGE_STYLES, AVAILABLE_PAGE_STYLES]), onClick: handleClick }, page)));
};
/**
 * A traditional pagination component that uses an arbitrary page
 * count current page. Unlike the PaginationBar component which
 * relies on a GraphQL connection to specify surrounding cursors,
 * this component just uses props to specify a page range.
 * @param props The configuration for this component.
 */
export var SimplePaginationBar = function (_a) {
    var currentPage = _a.currentPage, _b = _a.pageCount, pageCount = _b === void 0 ? 0 : _b, onPageClick = _a.onPageClick, _c = _a.maxPages, maxPages = _c === void 0 ? 7 : _c;
    if (pageCount <= 1) {
        return React.createElement(React.Fragment, null);
    }
    var truncationStyle = pageCount < maxPages
        ? 'none'
        : currentPage > Math.ceil(maxPages / 2) + 1 &&
            currentPage < pageCount - maxPages / 2 - 1
            ? 'intermediary'
            : currentPage < maxPages
                ? 'head'
                : 'tail';
    var pages = new Array(pageCount)
        .fill(0)
        .map(function (_, i) { return i + 1; })
        .filter(function (pageNumber) {
        switch (truncationStyle) {
            case 'head':
                return pageNumber < maxPages;
            case 'tail':
                return pageNumber > pageCount - maxPages;
            case 'intermediary':
                return (pageNumber > currentPage - maxPages / 2 &&
                    pageNumber < currentPage + maxPages / 2);
            default:
                return true;
        }
    });
    var previousPages = pages.filter(function (i) { return i < currentPage; });
    var nextPages = pages.filter(function (i) { return i > currentPage; });
    return (React.createElement(Content.Text, null,
        React.createElement("div", { className: CONTAINER_STYLES },
            React.createElement("ul", { className: PAGINATION_WRAPPER_STYLES },
                ['tail', 'intermediary'].includes(truncationStyle) ? (React.createElement(React.Fragment, null,
                    React.createElement(PageNumber, { page: 1, onClick: onPageClick }),
                    React.createElement("span", { className: TRUNCATION_STYLES }, "..."))) : null,
                previousPages.map(function (page) { return (React.createElement(PageNumber, { page: page, onClick: onPageClick })); }),
                React.createElement("li", { className: concatStyles([PAGE_STYLES, CURRENT_PAGE_STYLES]) }, currentPage),
                nextPages.map(function (page) { return (React.createElement(PageNumber, { page: page, onClick: onPageClick })); }),
                ['head', 'intermediary'].includes(truncationStyle) ? (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: TRUNCATION_STYLES }, "..."),
                    React.createElement(PageNumber, { page: pageCount, onClick: onPageClick }))) : null))));
};
