import React, { useState, useEffect, useMemo } from 'react';
import { FileUpload } from './FileUpload';
import { Progress } from './Progress';
export var InvisibleAttachment = function (_a) {
    var id = _a.id, file = _a.file, destination = _a.destination, onStatusChange = _a.onStatusChange;
    var _b = useState(null), uploadingId = _b[0], setUploadingId = _b[1];
    var _c = useState(0), progress = _c[0], setProgress = _c[1];
    var _d = useState(null), status = _d[0], setStatus = _d[1];
    var onUpload = useMemo(function () { return function (id, currentStatus, currentProgress) {
        setProgress(currentProgress);
        if (currentStatus !== status) {
            setStatus(currentStatus);
            if (onStatusChange) {
                onStatusChange(currentStatus);
            }
        }
    }; }, [status, setStatus, setProgress]);
    useEffect(function () {
        if (id !== uploadingId) {
            setUploadingId(id);
            FileUpload(id, file, destination, onUpload);
        }
    }, [file, destination, onUpload, useMemo]);
    return React.createElement(Progress, { progress: progress });
};
