var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Field, getErrorMessage } from './Field';
import { Dropdown } from './Dropdown';
var hasError = function (error) { return (error !== null && error !== void 0 ? error : '').length > 0; };
export var DropdownField = React.forwardRef(function (_a, forwardRef) {
    var style = _a.style, className = _a.className, children = _a.children, errorMessage = _a.errorMessage, errors = _a.errors, name = _a.name, props = __rest(_a, ["style", "className", "children", "errorMessage", "errors", "name"]);
    var determinedErrorMessage = getErrorMessage({
        name: name,
        errorMessage: errorMessage,
        errors: errors,
    });
    return (React.createElement(Field, __assign({}, props, { style: style, className: className, name: name, errorMessage: determinedErrorMessage }),
        React.createElement(Dropdown, __assign({}, props, { name: name, error: hasError(determinedErrorMessage), ref: forwardRef }), children)));
});
DropdownField.displayName = 'Form.DropdownField';
