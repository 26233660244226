import React, { useContext } from 'react';
import { NavigatorContext } from './NavigatorContext';
import { concatStyles } from 'utils';
var DRAWER_SECTION_STYLES = 'flex flex-row list-none relative box-border m-0';
var SECTION_TITLE_STYLES = 'text-contentAreaBackground text-sm font-body font-hairline m-0 p-4 box-border flex-1';
/**
 * A generic container for alternate content embedded in
 * the primary navigation drawer.
 */
export var Section = function (_a) {
    var children = _a.children;
    var theme = useContext(NavigatorContext).theme;
    var DRAWER_BG_STYLE = theme === 'secondary'
        ? 'bg-navSectionBackgroundAlt'
        : 'bg-navSectionBackground';
    return (React.createElement("li", { className: concatStyles(DRAWER_SECTION_STYLES, DRAWER_BG_STYLE) },
        React.createElement("div", { className: SECTION_TITLE_STYLES }, children)));
};
