import React from 'react';
import { concatStyles } from 'utils';
var STRIKE_CONTAINER_STYLES = 'flex py-2';
var STRIKE_BORDER_STYLES = 'h-px bg-contentAreaBorder my-auto flex-auto';
var STRIKE_CONTENT_STYLES = 'font-heading';
var STRIKE_CONTENT_INLINE_STYLES = { flex: '0 0 auto' };
export var Strike = function (_a) {
    var children = _a.children;
    return (React.createElement("div", { className: STRIKE_CONTAINER_STYLES },
        React.createElement("div", { className: concatStyles([STRIKE_BORDER_STYLES, 'mr-4']) }),
        React.createElement("div", { style: STRIKE_CONTENT_INLINE_STYLES, className: STRIKE_CONTENT_STYLES }, children),
        React.createElement("div", { className: concatStyles([STRIKE_BORDER_STYLES, 'ml-4']) })));
};
Strike.displayName = 'Content.Strike';
