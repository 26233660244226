var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Headers from '../Headers';
import * as Footers from '../Footers';
import * as Navigator from '../Navigator';
import { DrawerManagerContext, DrawerManager } from '../../Responsive';
import { FlashError } from './FlashError';
import { oftmcssSrc } from '../../svg';
var AppNavigatorLayout = function (_a) {
    var Logo = _a.logo, ProfileNavigation = _a.profileNavigation, renderDrawerItems = _a.renderDrawerItems, children = _a.children, _b = _a.drawerEnabled, drawerEnabled = _b === void 0 ? true : _b, handleSearchUpdate = _a.onSearchUpdate, handleLogoClick = _a.onClickLogo, props = __rest(_a, ["logo", "profileNavigation", "renderDrawerItems", "children", "drawerEnabled", "onSearchUpdate", "onClickLogo"]);
    var _c = useContext(DrawerManagerContext), drawerActive = _c.drawerActive, drawerCompact = _c.drawerCompact, toggleDrawer = _c.toggleDrawer;
    var pathname = useLocation().pathname;
    var _d = useState(false), profileActive = _d[0], setProfileActive = _d[1];
    var toggleProfile = function (active) {
        setProfileActive(active);
    };
    useEffect(function () {
        setProfileActive(false);
    }, [pathname]);
    useEffect(function () {
        if (drawerActive && !drawerEnabled) {
            toggleDrawer();
        }
    }, [drawerActive, drawerEnabled, toggleDrawer]);
    return (React.createElement(Navigator.Layout, null,
        React.createElement(Headers.Navigator, { onToggleDrawer: toggleDrawer, profileNavigation: ProfileNavigation, drawerActive: drawerActive, profileActive: profileActive, onToggleProfile: toggleProfile, profileName: props.profileName, profileColor: props.profileColor, profileImage: props.profileImage, onSearchUpdate: handleSearchUpdate, renderResults: props.renderSearchResults, logo: Logo, appearance: props.appearance, searchEnabled: props.searchEnabled, menuEnabled: props.menuEnabled, profileNavEnabled: props.profileNavEnabled, onClickLogo: handleLogoClick }),
        React.createElement(Navigator.Content, null,
            drawerEnabled && drawerActive && renderDrawerItems ? (React.createElement(Navigator.Drawer, { push: true, compact: drawerCompact !== null && drawerCompact !== void 0 ? drawerCompact : undefined }, renderDrawerItems())) : (React.createElement("span", null)),
            React.createElement(Navigator.Column, null,
                React.createElement(FlashError, null, children))),
        React.createElement(Footers.Standard, { footerImageSrc: oftmcssSrc, absolute: true })));
};
AppNavigatorLayout.displayName = 'AppNavigatorLayout';
export var AppNavigator = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(DrawerManager, null,
        React.createElement(AppNavigatorLayout, __assign({}, props), children)));
};
AppNavigator.displayName = 'AppNavigator';
