import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
var MAIN_MENU_BUTTON_WRAPPER_STYLES = 'flex flex-col box-border w-5/12 p-1 m-0';
var ICON_WRAPPER_STYLES = 'flex flex-col m-auto content-center justify-center';
var CONTAINER_STYLES = 'flex flex-col justify-center items-center w-full p-4 relative bg-primary rounded text-contentAreaBackground font-body hover:bg-navBackground hover:text-contentAreaBackground';
var LINK_STYLES = 'absolute top-0 left-0 h-full w-full opacity-0';
var LABEL_STYLES = 'flex text-contentAreaBackground my-2 mx-auto text-center';
export var MainMenuButton = function (_a) {
    var to = _a.to, icon = _a.icon, label = _a.label;
    return (React.createElement("div", { className: MAIN_MENU_BUTTON_WRAPPER_STYLES },
        React.createElement("div", { className: CONTAINER_STYLES },
            React.createElement("div", { className: ICON_WRAPPER_STYLES },
                React.createElement(FontAwesomeIcon, { icon: icon, size: "4x", className: "mx-auto" }),
                React.createElement("span", { className: LABEL_STYLES }, label)),
            React.createElement(Link, { className: LINK_STYLES, to: to }, label))));
};
