import React from 'react';
import * as Footers from '../Footers';
import { Navigator } from '../Headers';
import { EpcLogoXL } from '../../Brand';
var SHARED_STYLES = 'overflow-x-hidden bg-transparent w-full';
var SPLASH_CONTENT_WRAPPER_STYLES = SHARED_STYLES + " xl:w-1/3 pt-8";
var CHILDREN_WRAPPER_STYLES = SHARED_STYLES + " xl:w-2/3 ml-0 pt-0 -mt-8 lg:mt-0 md:pt-8 xl:ml-24";
var REGION_STYLES = 'm-0 box-border mr-auto max-w-xl';
var VIEW_CONTENT_STYLES = 'flex flex-col flex-grow flex-shrink h-auto lg:flex-row';
var CONTAINER_STYLES = 'flex flex-col m-0 box-border h-auto w-screen overflow-x-hidden';
/**
 * A responsive template that displays a collapsable two column layout.
 * @param props A configuration for the content and display of this template.
 */
export var MinimalistView = function (props) { return (React.createElement("div", { className: CONTAINER_STYLES },
    React.createElement(Navigator, { logo: EpcLogoXL }),
    React.createElement("div", { className: VIEW_CONTENT_STYLES },
        React.createElement("div", { className: SPLASH_CONTENT_WRAPPER_STYLES },
            React.createElement("div", { className: REGION_STYLES }, props.splashContent)),
        React.createElement("div", { className: CHILDREN_WRAPPER_STYLES },
            React.createElement("div", { className: REGION_STYLES }, props.children))),
    React.createElement(Footers.Standard, { footerImageSrc: props.footerImageSrc }))); };
