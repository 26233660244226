import React from 'react';
import { concatStyles } from 'utils';
import { HeadingSmall } from './HeadingSmall';
var PADDING_STYLES = 'pb-1';
var NO_PADDING_STYLES = 'pb-0';
var NO_BORDER_STYLES = 'border-b-0';
var BORDER_STYLES = 'border-b border-solid border-contentAreaBorder';
var HEADING_CARD_STYLES = 'text-primary text-xs my-2 mx-0 relative text-center font-heading';
export var HeadingCard = function (_a) {
    var border = _a.border, margin = _a.margin, bold = _a.bold, textTransform = _a.textTransform, children = _a.children, className = _a.className;
    return (React.createElement(HeadingSmall, { margin: margin, bold: bold, textTransform: textTransform, className: concatStyles([
            HEADING_CARD_STYLES,
            border ? BORDER_STYLES : NO_BORDER_STYLES,
            margin ? PADDING_STYLES : NO_PADDING_STYLES,
            className,
        ]) }, children));
};
HeadingCard.displayName = 'Content.HeadingCard';
